import { useNavigate as useDOMNavigate, To, NavigateOptions, NavigateFunction } from "react-router-dom";
import validator from "validator";

const goto = (url: string) => (window.location.href = url);

const useNavigate = () => {
  const push = useDOMNavigate();

  const navigate = (to: To | number, options?: NavigateOptions) => {
    if (typeof to === "string" && validator.isURL(to)) goto(to);
    else push(to as To, options);
  };

  return navigate as NavigateFunction;
};

export { goto };
export default useNavigate;
