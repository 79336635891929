import { createReduxEnhancer } from "@sentry/react";

import { StateProps as AuthProps } from "./features/auth";

const sentryEnhancer = createReduxEnhancer({
  stateTransformer: (state) => {
    const auth: AuthProps = { ...state.auth };

    if (auth.authenticate?.password) {
      return {
        ...state,
        auth: { ...auth, authenticate: { email: auth.authenticate.email, password: "" } },
      };
    }

    return state;
  },
});

const enhancers = [sentryEnhancer];

export default enhancers;
