function useDelayedRedirect(path, delay = 1000) {
  function redirect() {
    setTimeout(() => {
      window.location.replace(path);
    }, delay);
  }

  return redirect;
}

export default useDelayedRedirect;
