import { Flex, Box, ComponentWithAs, FlexProps } from "@chakra-ui/react";

import SignOut from "../SignOut";

const LeftSideBar: ComponentWithAs<"div", FlexProps> = ({ children, ...props }) => (
  <Flex display="flex" py="8" className="card" position="sticky" top="28" {...props}>
    <Flex width="100%" direction="column" gridRowGap="6">
      {children}
      <Box px="8">
        <SignOut />
      </Box>
    </Flex>
  </Flex>
);

export default LeftSideBar;
