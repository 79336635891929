import { useEffect, useRef } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  Skeleton,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import NumberFormat from "react-number-format";
import * as Yup from "yup";

import useUserAddress from "~/api/users/address";
import { useFetch, useUnfetch } from "~/hooks";
import useNamespace from "~/hooks/useNamespace";
import { Select, Option, Alert } from "~/theme/components";
import { ImperativeAlertProps } from "~/theme/components/Alert";

import { useSignedIn } from "../Layout";

export interface NameProps {
  common: string;
  official: string;
}

export interface CountriesProps {
  name: NameProps;
  independent: boolean;
}

export interface AddressProps {
  country: string;
  postal_code: string;
  state: string;
  city: string;
  neighborhood: string;
  address: string;
  service: string;
  complement: string;
}

export interface RegiaoProps {
  id: number;
  sigla: string;
  nome: string;
}

export interface UFProps {
  id: number;
  sigla: string;
  nome: string;
  regiao: RegiaoProps;
}

const schema = Yup.object({
  country: Yup.string().required("address@validation-country-required"),
  postal_code: Yup.string().when("country", {
    is: (value: string) => value === "Brazil",
    then: Yup.string()
      .required()
      .test(
        "Brazilian postal code",
        "address@validation-postal-code-invalid",
        (value) => value?.replace("-", "").replaceAll("_", "").length === 8
      ),
    otherwise: Yup.string().required(),
  }),
  address: Yup.string().required("address@validation-address-required"),
  neighborhood: Yup.string().required("address@validation-neighborhood-required"),
  city: Yup.string().required("address@validation-city-required"),
  state: Yup.string().required("address@validation-state-required"),
  complement: Yup.string().notRequired(),
}).required();

function Address() {
  const { user } = useSignedIn();
  const [requestAddress, { loading, error, data }] = useUserAddress();
  const countries = useFetch<CountriesProps[]>("https://restcountries.com/v3.1/all", {
    params: { fields: "name,independent" },
  });
  const [getUfs, ufs] = useUnfetch<Array<UFProps>>(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
  );
  const alertRef = useRef<ImperativeAlertProps>(null);
  const { translate } = useNamespace("address");

  const { formState, setValue, register, handleSubmit, watch, reset } = useForm<AddressProps>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
    defaultValues: user.address,
  });

  const address = watch();

  const isBrazil = address.country === "Brazil";

  const onSubmit: SubmitHandler<AddressProps> = async ({ postal_code, complement, ...data }) => {
    const updatedAddress = await requestAddress({
      ...data,
      postal_code: postal_code.replaceAll("-", ""),
      complement: `${complement}`,
    });

    alertRef.current?.turnOnAlert();
    reset(updatedAddress?.data);
  };

  const handler =
    <K extends keyof AddressProps>(type: K) =>
    (value: any) => {
      setValue((type as any) === "street" ? "address" : type, value);
    };

  const handlePostalCode: React.ChangeEventHandler<HTMLInputElement> = async ({ target }) => {
    if (target.value.length > 0) {
      setValue("postal_code", target.value, { shouldValidate: true, shouldTouch: true });
    }
    const zipcode = target.value;

    if (/^\d{5}-\d{3}$/.test(zipcode)) {
      const { data } = await axios.get<AddressProps>(`https://brasilapi.com.br/api/cep/v1/${zipcode}`);

      for (const [key, value] of Object.entries(data)) {
        handler(key as keyof AddressProps)(value);
      }
    }
  };

  useEffect(() => {
    if (isBrazil) {
      getUfs();
    }
  }, [getUfs, isBrazil]);

  return (
    <Flex direction="column" gridRowGap="8" width="inherit">
      <Alert status={data ? "success" : "error"} ref={alertRef} autoDisplay={false}>
        {error?.message ?? (data ? "Alterações salvas!" : undefined)}
      </Alert>
      <Flex direction="column" alignItems="start" gridRowGap="2">
        <Box textStyle="h5" color="gray.900">
          {translate("address@title")}
        </Box>
        <Box textStyle="body2" color="gray.700">
          {translate("address@subtitle")}
        </Box>
      </Flex>
      <FormControl
        as="form"
        display="flex"
        flexDirection="column"
        gridRowGap="8"
        width="inherit"
        isInvalid={formState.isDirty}
      >
        <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
          <SimpleGrid columns={2} spacingX={4} width="inherit">
            <Flex direction="column" justifyContent="space-between">
              <FormLabel htmlFor="country" textStyle="label.primary" color="black">
                {translate("address@form-country-label")}
              </FormLabel>
              <Skeleton isLoaded={!countries.isValidating}>
                <Select
                  onChangeValue={handler("country")}
                  id="country"
                  data-testid="country"
                  isDisabled={!countries.data}
                  value={address.country}
                  searchable={translate("address@form-country-select-placeholder")}
                >
                  {countries.data
                    ?.filter(({ independent }) => independent)
                    .map(({ name }) => (
                      <Option key={name.common} value={name.common}>
                        {name.common}
                      </Option>
                    ))}
                </Select>
              </Skeleton>
            </Flex>
            <Flex direction="column">
              <FormLabel htmlFor="postal_code" textStyle="label.primay" color="black">
                {translate("address@form-postal-code-label")}
              </FormLabel>
              <Input
                id="postal_code"
                data-testid="postal_code"
                isInvalid={!!formState.errors.postal_code}
                as={NumberFormat}
                format="#####-###"
                mask="_"
                value={address.postal_code}
                onChange={handlePostalCode}
                thousandSeparator={false}
                displayType="input"
                isDisabled={!address?.country}
                required
              />
            </Flex>
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={4} mt="-4" width="inherit">
            {formState.errors.postal_code && (
              <FormErrorMessage gridColumn="2" whiteSpace="nowrap">
                {translate(formState.errors.postal_code.message as "address@validation-postal-code-invalid")}
              </FormErrorMessage>
            )}
          </SimpleGrid>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="fullname" textStyle="label.primay" color="black">
              {translate("address@form-address-label")}
            </FormLabel>
            <Input
              type="text"
              id="address"
              data-testid="address"
              isInvalid={!!formState.errors.address}
              isDisabled={!address?.country}
              required
              {...register("address")}
            />
            {formState.errors.address && (
              <FormErrorMessage>
                {translate(formState.errors.address.message as "address@validation-address-required")}
              </FormErrorMessage>
            )}
          </Flex>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="fullname" textStyle="label.primay" color="black">
              {translate("address@form-neighborhood-label")}
            </FormLabel>
            <Input
              type="text"
              id="neighborhood"
              data-testid="neighborhood"
              isInvalid={!!formState.errors.neighborhood}
              isDisabled={!address?.country}
              required
              {...register("neighborhood")}
            />
            {formState.errors.neighborhood && (
              <FormErrorMessage>
                {translate(formState.errors.neighborhood.message as "address@validation-neighborhood-required")}
              </FormErrorMessage>
            )}
          </Flex>
          <SimpleGrid columns={2} spacing={4} width="inherit">
            <Flex direction="column">
              <FormLabel htmlFor="fullname" textStyle="label.primay" color="black">
                {translate("address@form-city-label")}
              </FormLabel>
              <Input
                type="text"
                id="city"
                data-testid="city"
                isInvalid={!!formState.errors.city}
                isDisabled={!address?.country}
                required
                {...register("city")}
              />
            </Flex>
            <Flex direction="column" width="inherit" justifyContent="space-between">
              <FormLabel htmlFor="state" textStyle="label.primary" color="black">
                {translate("address@form-state-label")}
              </FormLabel>
              <Skeleton isLoaded={!ufs.isValidating}>
                {isBrazil ? (
                  <Select
                    id="state"
                    data-testid="state"
                    onChangeValue={handler("state")}
                    isDisabled={!ufs?.data}
                    value={address.state}
                  >
                    {ufs.data?.map(({ sigla, nome }) => (
                      <Option key={sigla} value={sigla}>
                        {nome}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    type="text"
                    id="state"
                    data-testid="state"
                    isInvalid={!!formState.errors.state}
                    isDisabled={!address?.country}
                    required
                    {...register("state")}
                  />
                )}
              </Skeleton>
            </Flex>
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={4} mt="-4" width="inherit">
            {formState.errors.city && (
              <FormErrorMessage>
                {translate(formState.errors.city.message as "address@validation-city-required")}
              </FormErrorMessage>
            )}
            {formState.errors.state && (
              <FormErrorMessage gridColumn="2">
                {translate(formState.errors.state.message as "address@validation-state-required")}
              </FormErrorMessage>
            )}
          </SimpleGrid>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="fullname" textStyle="label.primay" color="black">
              {translate("address@form-complement-label")}
            </FormLabel>
            <Input
              type="text"
              id="complement"
              data-testid="complement"
              isInvalid={!!formState.errors.complement}
              isDisabled={!address?.country}
              required
              {...register("complement")}
            />
          </Flex>
        </Flex>
        <Box>
          <Button
            type="submit"
            data-testid="submit"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!(formState.isValid && formState.isDirty)}
            isLoading={loading}
            minWidth="24"
          >
            Salvar
          </Button>
        </Box>
      </FormControl>
    </Flex>
  );
}

export default Address;
