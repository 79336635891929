import { Flex } from "@chakra-ui/react";

import { ResponseProps as ProductProps } from "~/api/products/retrieve";
import LanguageMenu from "~/theme/components/LanguageMenu";

import { ProductLogo } from "./ProductLogo";

export interface HeaderProps {
  product: ProductProps;
}

const Header: ComponentWithoutChildren<HeaderProps> = ({ product }) => (
  <Flex
    bgColor="transparent"
    flexDirection="row"
    zIndex="overlay"
    justifyContent="space-between"
    alignItems="center"
    color="white"
    position="relative"
    width="100%"
    height="4.375rem"
    borderBottom="1px solid rgba(255, 255, 255, 0.3)"
    top="0"
    gap="1rem"
    px={["6", "8"]}
  >
    <ProductLogo product={product} />
    <LanguageMenu />
  </Flex>
);

export default Header;
