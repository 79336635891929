import { useState } from "react";

import {
  Box,
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  Flex,
  InputProps,
} from "@chakra-ui/react";

import useNamespace from "~/hooks/useNamespace";
import { CheckCircle, Visible, VisibleOff } from "~/theme/icons";
import passVerify from "~/utils/passVerify";

export interface PasswordProps extends InputProps {
  hint?: boolean;
}

const Password = forwardRef<PasswordProps, "input">(({ hint, ...props }, ref) => {
  const { isOpen: isVisible, onToggle } = useDisclosure();
  const [text, setText] = useState<string>();
  const { translate } = useNamespace("password");
  const Icon = isVisible ? VisibleOff : Visible;

  const passLength = (password: string) => {
    if (!password) return false;

    return password.length >= 8;
  };

  const handleInput: React.FormEventHandler<HTMLInputElement> = ({ target }) => {
    setText((target as HTMLInputElement).value as string);
  };

  return (
    <>
      <InputGroup>
        <Input ref={ref} type={isVisible ? "text" : "password"} autoComplete="" {...props} onInput={handleInput} />
        <InputRightElement>
          <IconButton
            aria-label="toggle visibility"
            onClick={onToggle}
            variant="unstyled"
            color={`${props.colorScheme}.300`}
            size="sm"
            marginTop="-0.5"
          >
            <Icon boxSize="6" />
          </IconButton>
        </InputRightElement>
      </InputGroup>
      {hint && (
        <Flex direction="column" alignItems="flex-start" my="3" gridRowGap="2">
          {[
            [translate("password@step-1"), passLength],
            [translate("password@step-2"), passVerify],
          ].map(([label, validate]) => {
            const color = (() => {
              if (!text) return ["gray.200", "gray.500"];

              return (validate as (value: string) => boolean)(text) ? "success.600" : "red.400";
            })();

            const isArray = Array.isArray(color);

            return (
              <Flex key={label as string} gridColumnGap="2">
                <CheckCircle boxSize="5" color={isArray ? color[0] : color} />
                <Box textStyle="body2" color={isArray ? color[1] : color}>
                  {label as string}
                </Box>
              </Flex>
            );
          })}
        </Flex>
      )}
    </>
  );
});

Password.displayName = "Input";

Password.defaultProps = {
  colorScheme: "purple",
  hint: false,
};

export default Password;
