import { memo } from "react";

import { Box, FlexProps, Flex, useRadio, UseRadioProps } from "@chakra-ui/react";

export interface OptionProps<T> extends FlexProps {
  value: T;
  defaultChecked?: boolean;
}

function Option<T>({ children, ...props }: OptionProps<T>) {
  const { getInputProps, getCheckboxProps } = useRadio(props as unknown as UseRadioProps);

  const input = getInputProps(props);
  const checkbox = getCheckboxProps(props);

  return (
    <Box
      as="label"
      _notLast={{
        borderBottomWidth: "0.0625rem",
        borderBottomColor: "gray.200",
      }}
    >
      <input tabIndex={-1} {...input} />
      <Flex
        tabIndex={0}
        my="2.5"
        color="black"
        textStyle="body2"
        cursor="pointer"
        justifyContent="flex-start"
        alignItems="center"
        userSelect="none"
        onKeyDown={(event) => {
          if (event.code === "Enter") {
            checkbox["aria-checked"] = true;
          }
        }}
        sx={{ "& *": { pointerEvents: "none" } }}
        {...checkbox}
      >
        {children}
      </Flex>
    </Box>
  );
}

export default memo(Option);
