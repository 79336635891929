import { Button, Link } from "@chakra-ui/react";

import { useNamespace } from "~/hooks";

const SupportButton = () => {
  const { translate } = useNamespace("contact");

  return (
    <Button
      position="fixed"
      bottom={10}
      right={10}
      as={Link}
      variant="secondary"
      href="https://help.fluency.io/"
      border="2px"
      zIndex="tooltip"
    >
      {translate("contact@help")}
    </Button>
  );
};

export default SupportButton;
