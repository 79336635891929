const error = {
  50: "#FFDEDE",
  100: "#f5c0c0",
  200: "#e89898",
  300: "#dc6f70",
  400: "#d14747",
  500: "#CC3333",
  600: "#902323",
  700: "#671718",
  800: "#400c0d",
  900: "#1d0101",
};

export default error;
