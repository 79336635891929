import { useState } from "react";

import { Box, Button, Flex, PinInput, PinInputField } from "@chakra-ui/react";

import { useQueryParam } from "~/hooks";
import useNamespace from "~/hooks/useNamespace";

type Props = {
  resendCode: () => void;
  resendCodeLoading: boolean;
  confirm: (code: string) => void;
  confirmLoading: boolean;
};

function CodeInput({ resendCodeLoading, resendCode, confirmLoading, confirm }: Props) {
  const query = useQueryParam("email");
  const { translate } = useNamespace("activate");
  const [code, setCode] = useState("");

  return (
    <>
      <Flex direction="column">
        <Box as="p" textStyle="h5" color="gray.900">
          {translate("activate@email-validate-title")}
        </Box>
        <Box textStyle="body2" color="gray.700" my="4">
          {translate("activate@insert-code-placeholder", { email: query.email })}
        </Box>
        <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
          <Flex direction="column" width="100%">
            <Flex width="100%" justifyContent="space-between">
              <PinInput type="alphanumeric" onChange={(value) => setCode(value.toUpperCase())} value={code}>
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
              </PinInput>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex width="100%" justifyContent="space-between">
        <Button isLoading={resendCodeLoading} onClick={resendCode} border="none" variant="link">
          {translate("activate@resend-code-button")}
        </Button>
        <Button
          borderRadius={6}
          minWidth="50%"
          disabled={code.length < 6}
          type="submit"
          isLoading={confirmLoading}
          onClick={() => confirm(code)}
        >
          {translate("activate@continue-code-button")}
        </Button>
      </Flex>
    </>
  );
}

export default CodeInput;
