import { useLocation } from "react-router-dom";

export default function useQueryParam<P extends string>(...params: Array<P>) {
  const location = useLocation();
  const url = new URLSearchParams(location.search);
  const properties = {} as Record<P, string | null>;

  for (const param of params) {
    properties[param] = url.get(param);
  }

  return properties;
}
