const success = {
  50: "#DEFBE6",
  100: "#b2fbda",
  200: "#85f7c4",
  300: "#57f4ac",
  400: "#2cf195",
  500: "#17d87c",
  600: "#0A9E5A",
  700: "#027843",
  800: "#004927",
  900: "#001a0a",
};

export default success;
