import { InitOptions } from "i18next";

import * as locales from "../locales";

const config: InitOptions = {
  resources: { ...locales },
  fallbackLng: { default: ["pt"] },
  interpolation: {
    escapeValue: false,
  },
};

export default config;
