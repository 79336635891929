import { createIcon } from "@chakra-ui/icons";

const Talks = createIcon({
  displayName: "Talks",
  viewBox: "0 0 237 237",
  path: (
    <>
      <path
        d="M88 104V173.137C88 204.535 65.0252 230.629 37.8925 236.817C33.34 237.854 29 234.348 29 229.654V104H88Z"
        fill="currentColor"
      />
      <path
        d="M29 89C29 55.8629 55.8629 29 89 29H149C182.137 29 209 55.8629 209 89V149C209 182.137 182.137 209 149 209H29V89Z"
        fill="currentColor"
      />
      <path
        d="M150.57 146.851V99.5895H167.51V81.4652H150.57V64H132V147.84C132 162.287 141.732 172 156.234 172H170V153.876H157.725C153.779 153.876 150.553 150.736 150.553 146.886L150.57 146.851Z"
        fill="white"
      />
      <path
        d="M79.5425 114.283C71.9149 110.943 66 103.597 66 92.9108C66 76.3653 79.9663 64 95.61 64H110V82.8541H96.2456C89.4831 82.8541 85.2456 87.2615 85.2456 93.3305C85.2456 99.3995 89.4655 103.807 96.2456 103.807H110V122.679H99.4238C90.5425 122.679 85.2456 130.234 85.2456 138.402V172H66.0177V138.402C66.2119 127.768 71.4559 118.411 79.5425 114.283Z"
        fill="white"
      />
    </>
  ),
});

export default Talks;
