import { Flex, Box, Image } from "@chakra-ui/react";

import { useDevice, useNamespace } from "~/hooks";

function LMS() {
  const { isMobileDevice } = useDevice();
  const { translate } = useNamespace("outdoor");

  return (
    <Flex direction="column" gap={["2", "4"]} width="100%" position="relative" mt={["0", "2rem"]}>
      <Box zIndex="10">
        <Box fontFamily="GT Walsheim" fontSize={["1.5rem", "4rem"]} color="white" fontWeight="light">
          {translate("outdoor@signIn-lms-1")}
        </Box>
        <Box fontFamily="GT Walsheim" fontSize={["1.5rem", "4rem"]} color="white" fontWeight="bold">
          {translate("outdoor@signIn-lms-2")}
        </Box>
        <Box fontFamily="GT Walsheim" fontSize={["1.5rem", "4rem"]} color="white" fontWeight="light">
          {translate("outdoor@signIn-lms-3")}
        </Box>
      </Box>
      {!isMobileDevice && (
        <>
          <Image
            width="13.375rem"
            height="13.375rem"
            src="/images/outdoor/LMS/square-1.gif"
            position="absolute"
            left="-60px"
            bottom="-260px"
            borderRadius="1rem"
          />
          <Image
            width="13.375rem"
            height="13.375rem"
            src="/images/outdoor/LMS/square-2.gif"
            position="absolute"
            left="300px"
            bottom="-360px"
            borderRadius="1rem"
          />
          <Image
            width="13.375rem"
            height="13.375rem"
            src="/images/outdoor/LMS/square-3.gif"
            position="absolute"
            top="90px"
            right="-120px"
            borderRadius="1rem"
            zIndex="0"
          />
        </>
      )}
    </Flex>
  );
}

export default LMS;
