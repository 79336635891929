import { FocusEventHandler, MouseEvent, MouseEventHandler, useRef } from "react";

import { Icon } from "@fluencyacademy/core-components-web";
import { useClickAway, useToggle } from "react-use";

import { ResponseProps } from "~/api/users/retrieve";

import { PLATFORMS_STORAGE_KEY } from "../data/platforms-repository";
import { useInitials } from "../hooks/use-initials";
import { useNavigation } from "../hooks/use-navigation";
import { useTranslations } from "../hooks/use-translations";
import { FluencyIcon } from "../icons/fluency-icon";
import { LogoutIcon } from "../icons/logout-icon";
import { ProfileIcon } from "../icons/profile-icon";

export type UserMenuProps = {
  user?: ResponseProps;
};

function UserMenu({ user }: UserMenuProps) {
  const userRef = useRef<HTMLDivElement>(null);
  const initials = useInitials({ user });
  const [isOpen, toggle] = useToggle(false);
  const canToggle = useRef(true);
  const { navigate } = useNavigation();
  const t = useTranslations("productsHub");

  useClickAway(userRef, () => {
    if (isOpen) toggle(false);
  });

  const handlePopup: MouseEventHandler<HTMLButtonElement> | FocusEventHandler<HTMLButtonElement> = (
    event: FocusEvent | MouseEvent
  ) => {
    if (event.type === "mouseenter" && isOpen) return;

    if (event.type === "mouseenter" && !isOpen) {
      canToggle.current = false;

      setTimeout(() => {
        canToggle.current = true;
      }, 500);

      toggle();
    }

    if (canToggle.current) toggle();
  };

  function handleSignout() {
    localStorage.removeItem(PLATFORMS_STORAGE_KEY);

    navigate("/signout");
  }

  function handleProfile() {
    navigate("/profile");
  }
  function handleProductsHub() {
    navigate("/");
  }

  if (!user) return <div />;

  return (
    <div ref={userRef} className="relative w-9 cursor-pointer">
      <button className="flex flex-row gap-1 items-center" onClick={handlePopup}>
        <div className="bg-[#EDE5FC] text-brand-primary-700 relative flex h-[24px] w-[24px] select-none items-center justify-center rounded-full bg-avatar font-gt font-bold uppercase leading-[26px] text-[0.75rem]">
          {initials}
        </div>
        {isOpen ? (
          <Icon icon="chevron-up" size={24} className="text-basic-white-50" />
        ) : (
          <Icon icon="chevron-down" size={24} className="text-basic-white-50" />
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 z-50 mt-[9px] w-[220px] cursor-default rounded-[18px] bg-basic-white-50 px-[23px] py-[19px] shadow-xl">
          <div className="flex flex-row items-center gap-3">
            <div className="bg-brand-primary-700 text-basic-white-50 relative flex h-[28px] w-[28px] select-none items-center justify-center rounded-full bg-avatar font-gt font-bold uppercase leading-[26px] text-[0.875rem]">
              {initials}
            </div>
            <div className="flex flex-col">
              <p className="font-gt text-[12px] font-medium leading-[18px] text-[#666666]">Hello,</p>
              <p className="font-ibm text-[14px] font-bold leading-[18px] text-extended-neutral-400">{user?.name}!</p>
            </div>
          </div>
          <hr className="my-[14px] h-[1px] w-full border-0 bg-[#DFE6FF]" />
          <button
            onClick={() => handleProfile()}
            className="m-0 flex w-full appearance-none items-center gap-3 border-0 p-0 text-brand-primary-pure"
          >
            <ProfileIcon className="w-[29px] h-[28px] md:w-[24px] md:h-[24px]" />
            <span className="font-ibm text-[14px] font-medium leading-[18px] text-brand-primary-700">
              {t("general.user.profile")}
            </span>
          </button>
          <hr className="my-[14px] h-[1px] w-full border-0 bg-[#DFE6FF]" />
          <button
            onClick={() => handleProductsHub()}
            className="m-0 flex w-full appearance-none items-center gap-3 border-0 p-0 text-brand-primary-pure"
          >
            <FluencyIcon className="w-[29px] h-[28px] md:w-[24px] md:h-[24px]" />
            <span className="font-ibm text-[14px] font-medium leading-[18px] text-brand-primary-700">
              {t("general.user.products")}
            </span>
          </button>
          <hr className="my-[14px] h-[1px] w-full border-0 bg-[#DFE6FF]" />
          <button
            onClick={() => handleSignout()}
            className="m-0 flex w-full appearance-none items-center gap-3 border-0 p-0 text-brand-primary-pure"
          >
            <LogoutIcon className="w-[29px] h-[28px] md:w-[24px] md:h-[24px]" />
            <span className="font-ibm text-[14px] font-medium leading-[18px] text-brand-primary-700">
              {t("general.user.logout")}
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

export { UserMenu };
