import { createIcon } from "@chakra-ui/icons";

const ZoomOut = createIcon({
  displayName: "ZoomOut",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M14.577 10.3118V11.5304C14.577 11.7818 14.3714 11.9874 14.12 11.9874H7.7222C7.47085 11.9874 7.26521 11.7818 7.26521 11.5304V10.3118C7.26521 10.0604 7.47085 9.8548 7.7222 9.8548H14.12C14.3714 9.8548 14.577 10.0604 14.577 10.3118ZM22.2315 21.1538L21.1538 22.2315C20.7958 22.5895 20.217 22.5895 19.8628 22.2315L16.0622 18.4347C15.8908 18.2634 15.7956 18.0311 15.7956 17.7873V17.1666C14.4513 18.2177 12.7605 18.8422 10.9211 18.8422C6.54545 18.8422 3 15.2967 3 10.9211C3 6.54545 6.54545 3 10.9211 3C15.2967 3 18.8422 6.54545 18.8422 10.9211C18.8422 12.7605 18.2177 14.4513 17.1666 15.7956H17.7873C18.0311 15.7956 18.2634 15.8908 18.4347 16.0622L22.2315 19.859C22.5857 20.217 22.5857 20.7958 22.2315 21.1538ZM16.1003 10.9211C16.1003 8.05732 13.7849 5.74192 10.9211 5.74192C8.05732 5.74192 5.74192 8.05732 5.74192 10.9211C5.74192 13.7849 8.05732 16.1003 10.9211 16.1003C13.7849 16.1003 16.1003 13.7849 16.1003 10.9211Z"
      fill="currentColor"
    />
  ),
});

export default ZoomOut;
