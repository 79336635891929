import { Image } from "@chakra-ui/react";

import { ResponseProps as ProductProps } from "~/api/products/retrieve";
import { Company } from "~/theme/icons";

export interface Props {
  product: ProductProps;
}

function ProductLogo({ product }: Props) {
  if (!product.logo) {
    return <Company width="28" height="9" />;
  }

  if (product.name === "Talks") {
    return <Image src={product.logo} height={{ base: "20px", lg: "30px" }} alt={product.name} title={product.name} />;
  }

  return <Image src={product.logo} alt={product.name} title={product.name} />;
}

export { ProductLogo };
