import { useMemo } from "react";

import { ResponseProps } from "~/api/users/retrieve";

import { extractInitials } from "../utils/extract-initials";

type UseInitialsProps = {
  user?: ResponseProps;
};

function useInitials({ user }: UseInitialsProps) {
  const initials = useMemo(() => {
    if (user?.name) {
      return extractInitials(user.name);
    }

    return "";
  }, [user?.name]);

  return initials;
}

export { useInitials };
