import { useNamespace } from "~/hooks";

import { Phrase } from "../";

function Activate() {
  const { translate } = useNamespace("activate");

  return <Phrase lineHeight="70px">{translate("activate@outdoor")}</Phrase>;
}

export default Activate;
