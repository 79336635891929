import { useNamespace, useProduct } from "~/hooks";

import { Phrase, LMS, FluencyIo } from "../";

function SignIn() {
  const { name } = useProduct();
  const { translate } = useNamespace("outdoor");

  if (name === "Yowza") return <Phrase mt={["0", "4.375rem"]}>Your voice, unlocked.</Phrase>;
  if (name === "LMS") return <LMS />;
  if (name === "fluency-io") return <FluencyIo />;

  return <Phrase>{translate("outdoor@signIn-accounts")}</Phrase>;
}

export default SignIn;
