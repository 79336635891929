import { Cookies } from "react-cookie";

export const USER_LOCALE_COOKIE = "@Accounts:user_locale";

export interface LanguageProps {
  locale: string;
  key: string;
  flag: string;
  waitListUrl: string;
}

export const LANGUAGES: Array<LanguageProps> = [
  {
    locale: "pt",
    key: "languageMenu@portuguese",
    flag: "/images/flags/brazil.png",
    waitListUrl: "https://hub.fluency.io/br/fa/lista-de-espera",
  },
  {
    locale: "en",
    key: "languageMenu@english",
    flag: "/images/flags/usa.png",
    waitListUrl: "https://hub.fluency.io/br/fa/lista-de-espera",
  },
  {
    locale: "es",
    key: "languageMenu@spanish",
    flag: "/images/flags/spain.png",
    waitListUrl: "https://hub.fluency.io/es/fa/lista-de-espera-b",
  },
];

export const DEFAULT_LOCALE = "pt";

export function mapLocale(locale: string) {
  if (locale.startsWith("en")) {
    return "en";
  } else if (locale.startsWith("es")) {
    return "es";
  } else if (locale.startsWith("pt")) {
    return "pt";
  }

  return DEFAULT_LOCALE;
}

export function getCurrentLocale(): string {
  const cookies = new Cookies();
  const cookieLocale = cookies.get(USER_LOCALE_COOKIE);
  const browserLocale = navigator.language || window.navigator.language;

  const l = cookieLocale || browserLocale || DEFAULT_LOCALE;
  const currentLocale = mapLocale(l);

  return currentLocale;
}

export function setCurrentLocale(locale: string) {
  const cookies = new Cookies();

  cookies.set(USER_LOCALE_COOKIE, mapLocale(locale));
}
