import { Box, Button, FormLabel, GridItem, Input, SimpleGrid } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useForm } from "react-hook-form";

import model from "./model.json";

const Generator = () => {
  const { register, handleSubmit } = useForm({ defaultValues: model });

  const onSubmit = async (data: typeof model) => {
    const zip = new JSZip();
    const folder = zip.folder("locale");

    const model = {};

    for (const [key, value] of Object.entries(data)) {
      const [ns] = key.split("@");

      if (ns in model) model[ns][key] = value;
      else model[ns] = { [key]: value };
    }

    if (folder) {
      for (const ns of Object.keys(model)) folder.file(`${ns}.json`, JSON.stringify(model[ns]));

      const content = await zip.generateAsync({ type: "blob" });

      saveAs(content, "lang.zip");
    }
  };

  return (
    <Box bg="purple.300" m="0" boxSizing="border-box" padding="12">
      <SimpleGrid columns={2} spacing={4} width="60vw" margin="auto" padding="10" borderRadius="3xl" bg="white">
        <Box textStyle="h5" textAlign="center" fontSize="5xl" color="gray.900" gridColumn="1 / 3" mb="10">
          Traduza as frases abaixo
        </Box>
        {Object.entries(model).map(([key, value]) => (
          <Box key={key}>
            <GridItem display="grid" alignItems="center" justifyContent="start">
              <FormLabel margin="auto">{value}</FormLabel>
            </GridItem>
            <GridItem>
              <Input id={key} {...register(`${key}` as "address@title")} />
            </GridItem>
          </Box>
        ))}
        <Button gridColumn="1 / 3" onClick={handleSubmit(onSubmit)} mt="6">
          Baixar tradução
        </Button>
      </SimpleGrid>
    </Box>
  );
};

export default Generator;
