import bifrostHttp from "~/services/bifrostHttp";

import { PlatformsResponse } from "../types/platform";

export const PLATFORMS_STORAGE_KEY = "platforms";
const EXPIRY_TIME = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const fetchLocalData = (): PlatformsResponse | void => {
  const now = new Date().getTime();

  const storedData = localStorage.getItem(PLATFORMS_STORAGE_KEY);

  if (storedData) {
    const { data, timestamp } = JSON.parse(storedData);

    if (now - timestamp < EXPIRY_TIME) {
      return data;
    }
  }
};

const saveLocalData = (data: PlatformsResponse) => {
  const now = new Date().getTime();

  localStorage.setItem(PLATFORMS_STORAGE_KEY, JSON.stringify({ data: data, timestamp: now }));
};

const fetchApiData = async (): Promise<PlatformsResponse> => {
  const response = await bifrostHttp({ url: "/platforms", method: "GET" });

  if (response.status > 300) {
    throw new Error(response.statusText);
  }

  return response.data;
};

export { fetchLocalData, saveLocalData, fetchApiData };
