import React from "react";

import { createRoot } from "react-dom/client";

import App from "./app";
import { setupAmplitude } from "./services/amplitude";
import initSentry from "./services/sentry";
import "./globals.css";

initSentry();
setupAmplitude();

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
