import { Box, Button, Flex, Image } from "@chakra-ui/react";

import { Container } from "~/auth/Layout/components";
import { useProduct } from "~/hooks";
import { TimesCircle, ArrowLeft, Account, Company } from "~/theme/icons";

export default function Error() {
  const data = useProduct();

  return (
    <Container width="100%" bgImage={`url('${data.background_image}')`}>
      {(goBack) => (
        <Flex
          width={["90%", "md"]}
          minHeight="2xs"
          px={["6", "12"]}
          py={["8", "12"]}
          borderRadius="3xl"
          bgColor="white"
          alignItems="center"
          direction="column"
          gridRowGap={["4", "8"]}
        >
          {data?.logo ? (
            <Flex direction="column" justify="center" align="center" position="relative" pb="4">
              <Image width="36" height="10" src={data.logo} />
              <Box textStyle="caption2" position="absolute" inset="auto" bottom={["-3", "-6"]} whiteSpace="nowrap">
                Você está acessando o <strong>{data.name}</strong>!
              </Box>
            </Flex>
          ) : (
            <Account width="28" height="6" color="gray.900" />
          )}
          <Flex direction="column" justifyContent="center" alignItems="center" gap="4">
            <TimesCircle w="3.3963rem" h="3.3963rem" color="purple.300" />
            <Box textStyle="h5" textAlign="center" color="gray.900">
              Esta página nao existe!
            </Box>
            <Box textStyle="body2" color="gray.700" textAlign="center" maxWidth="56">
              Clique no botão de voltar, para retornar a página anterior
            </Box>
          </Flex>
          <Button onClick={goBack} variant="primary" isFullWidth>
            <ArrowLeft marginRight="2.5" />
            Voltar
          </Button>
          <Company width="24" height="8" color="gray.300" />
        </Flex>
      )}
    </Container>
  );
}
