import { AppComponent } from "@fluencyacademy/core-components-web";

import { useTranslations } from "../hooks/use-translations";
import { HomeProduct } from "../types/product";
import ProductContent from "./product-content";
import ProductTag from "./product-tag";

const ProductMemorizationContent: AppComponent = () => {
  const t = useTranslations("productsHub");

  return (
    <ProductContent
      product={HomeProduct.MEMORIZATION}
      title={t("memorization_product.content_title")}
      description={t("memorization_product.description")}
      backgroundColor="bg-brand-primary-mem-600"
      mainImgSrc={"/images/memorization_content.png"}
      extraHeader={<ProductTag label={t("memorization_product.tag")} backgroundColor="bg-brand-primary-mem-700" />}
    />
  );
};

export default ProductMemorizationContent;
