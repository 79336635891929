import { createIcon } from "@chakra-ui/icons";

const Persona = createIcon({
  displayName: "Persona",
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0433 10.5384C13.2293 11.0805 12.2724 11.3697 11.2936 11.3695C9.98178 11.3675 8.72431 10.8469 7.79686 9.92186C6.8694 8.99681 6.34766 7.74278 6.34602 6.43473C6.34602 5.45868 6.63629 4.50455 7.18013 3.69301C7.72396 2.88147 8.49693 2.24898 9.40127 1.87552C10.3056 1.50206 11.3007 1.4044 12.2607 1.59491C13.2207 1.78542 14.1025 2.25553 14.7946 2.94579C15.4866 3.63604 15.9578 4.51544 16.1487 5.47277C16.3395 6.43009 16.2413 7.42234 15.8665 8.32402C15.4918 9.2257 14.8573 9.99632 14.0433 10.5384ZM17.2129 13.888L18.0676 18.2113C18.1131 18.5092 18.0802 18.8138 17.9723 19.0952C17.8644 19.3767 17.6851 19.6254 17.452 19.8171C17.3058 19.927 13.841 22.5 11.2954 22.5C8.74978 22.5 5.28501 19.927 5.13883 19.8171C4.905 19.6258 4.72499 19.3773 4.61642 19.0958C4.50785 18.8143 4.47444 18.5095 4.51946 18.2113L5.3742 13.888C5.43902 13.5953 5.60047 13.3327 5.8327 13.1424C6.06493 12.952 6.35451 12.8448 6.65506 12.8381H15.932C16.2326 12.8448 16.5221 12.952 16.7544 13.1424C16.9866 13.3327 17.1481 13.5953 17.2129 13.888Z"
      fill="currentColor"
    />
  ),
});

export default Persona;
