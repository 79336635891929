import useNavigate from "~/hooks/useNavigate";

const useNavigation = (path?: string) => {
  const navigate = useNavigate();

  const openUrl = () => {
    window.open(path, "_blank");
  };

  return {
    navigate,
    openUrl,
  };
};

export { useNavigation };
