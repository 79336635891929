import { Box } from "@chakra-ui/react";

import useNamespace from "~/hooks/useNamespace";

import { useLoggedOut } from "../../index";

function ProductSpan() {
  const { ...product } = useLoggedOut();
  const { translate } = useNamespace("signIn");

  if (product.name === "Fluency Academy") return null;

  return (
    <Box alignSelf="center" as="span" textStyle="caption2" whiteSpace="nowrap">
      {translate("signIn@you-are-accessing")} <strong>{product.name}</strong>
    </Box>
  );
}

export default ProductSpan;
