import { useReducer } from "react";

import { AxiosRequestConfig, AxiosResponse } from "axios";

import http from "~/services/http";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface State<T> {
  loading: boolean;
  error?: Error;
  data?: T;
}

type Action<T> = { type: "loading" } | { type: "fetched"; payload: T } | { type: "error"; payload: Error };

function useMutate<T = unknown, J = unknown>(
  url?: string,
  options?: AxiosRequestConfig
): [(params: J) => Promise<AxiosResponse<T> | undefined>, State<T>] {
  const initialState: State<T> = {
    loading: false,
    error: {} as Error,
    data: {} as T,
  };

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState, loading: true };
      case "fetched":
        return { ...initialState, loading: false, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload, loading: false };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const mutate = async (data: J): Promise<AxiosResponse<T> | undefined> => {
    dispatch({ type: "loading" });

    try {
      const response = await http({ url, method: "POST", data, ...options });

      if (response.status > 300) {
        throw new Error(response.statusText);
      }

      dispatch({ type: "fetched", payload: response.data });

      return response;
    } catch (error) {
      dispatch({ type: "error", payload: error as Error });
    }
  };

  return [mutate, { ...state }];
}

export function useMutateWithException<T = unknown, J = unknown>(
  url?: string,
  options?: AxiosRequestConfig
): [(params: J) => Promise<AxiosResponse<T> | undefined>, State<T>] {
  const initialState: State<T> = {
    loading: false,
    error: {} as Error,
    data: {} as T,
  };

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState, loading: true };
      case "fetched":
        return { ...initialState, loading: false, data: action.payload };
      case "error":
        return { ...initialState, error: action.payload, loading: false };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const mutate = async (data: J): Promise<AxiosResponse<T> | undefined> => {
    dispatch({ type: "loading" });

    try {
      const response = await http({ url, method: "POST", data, ...options });

      if (response.status > 300) {
        throw new Error(response.statusText);
      }

      dispatch({ type: "fetched", payload: response.data });

      return response;
    } catch (error) {
      dispatch({ type: "error", payload: error as Error });
      throw error;
    }
  };

  return [mutate, { ...state }];
}

export default useMutate;
