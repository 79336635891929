import { withSentryReactRouterV6Routing } from "@sentry/react";
import { Route, Routes as Switch } from "react-router-dom";

import {
  EmailList,
  ForgotEmail,
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignOut,
  SignUp,
  ValidateEmail,
  ValidatePhone,
} from "~/auth";
import LoggedOut from "~/auth/Layout";
import ProfileSignUp from "~/auth/Profile";
import Provisional from "~/auth/Provisional";
import LoggedInHome from "~/features/products-hub/layout";
import ProductHub from "~/features/products-hub/page";
import Generator from "~/languages/Generator";
import Activate from "~/pages/Activate";
import Code from "~/pages/Activate/Code";
import Address from "~/pages/Address";
import Contact from "~/pages/Contact";
import LoggedIn from "~/pages/Layout";
import ChangePassword from "~/pages/Password";
import Profile from "~/pages/Profile";

import Error from "./Error";
import useSession from "./useSession";

function Routes() {
  const { withSession, withNoSession } = useSession();
  const HomeSession = withSession(LoggedInHome);
  const Session = withSession(LoggedIn);
  const NoSession = withNoSession(LoggedOut);
  const SentryRoutes = withSentryReactRouterV6Routing(Switch);

  return (
    <SentryRoutes>
      <Route path="/" element={<HomeSession />}>
        <Route element={<ProductHub />} index />
      </Route>
      <Route path="/" element={<Session />}>
        <Route element={<Profile />} path="profile" />
        <Route element={<ChangePassword />} path="password" />
        <Route element={<Address />} path="address" />
        <Route element={<Contact />} path="contact" />
      </Route>
      <Route element={<NoSession />} path="/signin/">
        <Route element={<SignIn />} index />
        <Route element={<Provisional />} path="provisional-password" />
        <Route element={<ForgotPassword />} path="forgot-password" />
        <Route element={<ResetPassword />} path="forgot-password/reset" />
        <Route element={<ForgotEmail />} path="forgot-email" />
        <Route element={<EmailList />} path="forgot-email/list" />
      </Route>
      <Route
        element={<NoSession exceptRoutes={[{ path: "/signup/validate-phone" }, { path: "/signup/profile" }]} />}
        path="/signup/"
      >
        <Route element={<SignUp />} index />
        <Route element={<ValidateEmail />} path="validate-email" />
        <Route element={<ValidatePhone />} path="validate-phone" />
        <Route element={<ProfileSignUp />} path="profile" />
      </Route>
      <Route element={<NoSession />} path="/activate">
        <Route element={<Activate />} index />
        <Route element={<Code />} path="code" />
      </Route>
      <Route element={<SignOut />} path="/signout" />
      <Route element={<Generator />} path="lang/create" />
      <Route element={<Error />} path="*" />
    </SentryRoutes>
  );
}

export default Routes;
