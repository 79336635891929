import { useRef } from "react";

import { Box, Button, Flex, FormControl, FormLabel, Grid, Input, useDisclosure } from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";

import useNamespace from "~/hooks/useNamespace";
import { PhoneInputProps, Phone, Alert, ImperativeAlertProps } from "~/theme/components";

import { useSignedIn } from "../Layout";
import { ChangePhoneModal } from "./components";

export interface ContactProps {
  phone: PhoneInputProps;
  email: string;
}

function Contact() {
  const { user } = useSignedIn();
  const { translate } = useNamespace("contact");
  const modal = useDisclosure();
  const alertRef = useRef<ImperativeAlertProps>(null);

  const { control, register, formState, getValues, reset } = useForm<ContactProps>({
    defaultValues: {
      ...user,
      get phone() {
        if (!("phone_number" in user)) return {};
        const regExp = new RegExp(/^(\d.*)\s\((.*)\)\s(\d.*)/);
        const phoneNumber = regExp.exec(user.phone_number as string);

        if (!phoneNumber?.length) return {};

        const [_, ddi, ddd, number] = phoneNumber;

        return {
          code: ddi,
          number: `${ddd} ${number.replace("-", "")}`,
        };
      },
    },
  });

  const resetForm = (code: string, number: string) => {
    reset({
      phone: {
        code,
        number,
      },
    });
  };

  return (
    <>
      <Flex direction="column" gridRowGap="8" width="inherit">
        <Alert status="success" ref={alertRef} autoDisplay={false}>
          {translate("contact@form-success-message")}
        </Alert>
        <Flex direction="column" alignItems="start" gridRowGap="2">
          <Box textStyle="h5" color="gray.900">
            {translate("contact@title")}
          </Box>
          <Box textStyle="body2" color="gray.700">
            {translate("contact@subtitle")}
          </Box>
        </Flex>
        <FormControl
          as="form"
          display="flex"
          flexDirection="column"
          gridRowGap="8"
          width="inherit"
          isInvalid={formState.isDirty}
        >
          <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="8">
            <Flex direction="column" width="inherit" alignItems="flex-start">
              <FormLabel htmlFor="phone_number" textStyle="label.primay" color="black">
                {translate("contact@form-phone-number-label")}
              </FormLabel>
              <Grid gridTemplateColumns={["100%", "3fr 1fr"]} gap="4">
                <Controller
                  control={control}
                  name="phone"
                  defaultValue={getValues("phone")}
                  render={({ field }) => <Phone isDisabled {...field} />}
                />
                <Flex align="center" justify={["start", "center"]}>
                  <Button
                    onClick={modal.onOpen}
                    variant="terciary"
                    lineHeight="inherit"
                    fontSize="1rem"
                    borderRadius="lg"
                  >
                    {translate("contact@form-phone-number-button")}
                  </Button>
                </Flex>
              </Grid>
            </Flex>
            <Flex direction="column" width="inherit">
              <FormLabel htmlFor="email" textStyle="label.primary" color="black">
                {translate("contact@form-email-label")}
              </FormLabel>
              <Input
                id="email"
                type="email"
                data-testid="email"
                isInvalid={!!formState.errors.email}
                isDisabled
                {...register("email")}
              />
              <Box textStyle="body2" color="gray.600" marginTop="2">
                {translate("contact@form-email-information-label")}
                <Button as={NavLink} to="/" variant="terciary" lineHeight="inherit" fontSize="sm" marginLeft="1">
                  {translate("contact@form-suport-link")}
                </Button>
                .
              </Box>
            </Flex>
          </Flex>
        </FormControl>
      </Flex>
      <ChangePhoneModal phone={user.phone_number} resetForm={resetForm} pageAlertRef={alertRef} {...modal} />
    </>
  );
}

export default Contact;
