import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import { PhoneNavigateProps } from "~/auth/Validate/Phone";
import { useNamespace } from "~/hooks";

import { Phrase, Step } from "..";

function StepOne() {
  const { state } = useLocation() as { state: PhoneNavigateProps };
  const { translate } = useNamespace("outdoor");

  return (
    <Flex direction="column" gap={["2", "4"]} mt={["0", "4.375rem"]} align="start">
      <Phrase>{translate("outdoor@signUp-step3")}</Phrase>
      {state?.isCreatingAccount && <Step>{translate("outdoor@step-3")}</Step>}
    </Flex>
  );
}

export default StepOne;
