import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import enhancers from "./enhancers";
import { StateProps as AuthProps } from "./features/auth";
import reducer from "./reducer";

export interface RootStateProps {
  auth: AuthProps;
}

const store = configureStore({
  reducer,
  enhancers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export default store;
