import { Flex, Image } from "@chakra-ui/react";

import { useDevice, useNamespace } from "~/hooks";

import { Phrase } from "..";

function LMS() {
  const { isMobileDevice } = useDevice();
  const { translate } = useNamespace("outdoor");

  return (
    <Flex direction="column" mt={["0", "4.375rem"]}>
      <Phrase>{translate("outdoor@signIn-fluencyIo")}</Phrase>
      {!isMobileDevice && (
        <Flex>
          <Image src="/images/outdoor/fluencyIo/teacherRhavi.png" />
          <Image src="/images/outdoor/fluencyIo/teacherPaula.png" />
        </Flex>
      )}
    </Flex>
  );
}

export default LMS;
