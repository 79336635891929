import { useRef, useEffect, useCallback } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  SimpleGrid,
  Skeleton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { StringOrNumber } from "@chakra-ui/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateTime } from "luxon";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as Yup from "yup";

import useLanguages from "~/api/languages/list";
import { ResponseProps as UserProps } from "~/api/users/retrieve";
import { useUpdateUser } from "~/api/users/update";
import useNamespace from "~/hooks/useNamespace";
import { Select, Option, Alert, Gender, GenderTypes } from "~/theme/components";
import { ImperativeAlertProps } from "~/theme/components/Alert";
import birthdateValidator, { BirthdateProps } from "~/utils/birthdate";
import castObject from "~/utils/castObject";

import { useSignedIn } from "../Layout";

export interface ProfileProps extends Omit<UserProps, "birthdate"> {
  birthdate: BirthdateProps;
  custom_gender: string;
}

const schema = Yup.object({
  name: Yup.string().nullable().required("profile@validation-name-required"),
  birthdate: Yup.object({
    day: Yup.string().nullable(),
    month: Yup.string().nullable(),
    year: Yup.string().nullable(),
  }),
  gender: Yup.string().nullable(),
  custom_gender: Yup.string()
    .when("gender", {
      is: (value: string) => value === "CUSTOM",
      then: Yup.string().required("profile@validation-gender-required"),
    })
    .nullable(),
  native_language: Yup.string().nullable(),
}).required();

function Profile() {
  const languages = useLanguages();
  const { user } = useSignedIn();
  const alertRef = useRef<ImperativeAlertProps>(null);
  const { translate } = useNamespace("profile");
  const [updateUser, { loading, error, data }] = useUpdateUser();
  const shouldReplaceGender = !Object.values(GenderTypes).includes(user?.gender as any as GenderTypes) && user?.gender;

  const getDefaultValues = useCallback(
    () =>
      ({
        ...user,
        gender: shouldReplaceGender ? GenderTypes.CUSTOM : user.gender,
        custom_gender: shouldReplaceGender ? user.gender : undefined,
        avatar: null,
        get birthdate() {
          if (!user?.birthdate) return null;

          const { year, month, day } = DateTime.fromFormat(user.birthdate as unknown as string, "yyyy-MM-dd");

          return castObject({ year, month, day }, "string") as unknown as BirthdateProps;
        },
      } as ProfileProps),
    [shouldReplaceGender, user]
  );

  const { formState, control, register, handleSubmit, watch, reset, getValues } = useForm<ProfileProps>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [user, reset, getDefaultValues]);

  const { isFilled, isBeeingFilled } = birthdateValidator({ ...(watch("birthdate") ?? getValues("birthdate")) });

  const isCustomGender = watch("gender") === GenderTypes.CUSTOM;

  const columns = useBreakpointValue([{ columns: 1 }, { columns: isCustomGender ? 3 : 2 }]);

  const onSubmit: SubmitHandler<ProfileProps> = async ({ birthdate, custom_gender, ...data }) => {
    const hasBirthdate = birthdate?.day && birthdate?.month && birthdate?.year;
    const updatedData = hasBirthdate
      ? {
          ...data,
          gender: data.gender !== GenderTypes.CUSTOM ? data.gender : custom_gender,
          birthdate: DateTime.fromObject(castObject(birthdate as BirthdateProps, "number")).toFormat("yyyy-MM-dd"),
        }
      : data;
    const updatedUser = await updateUser(updatedData);

    alertRef.current?.turnOnAlert();
    const shouldReplaceGender = !Object.values(GenderTypes).includes(updatedUser?.data?.gender as any as GenderTypes);

    let updatedBirthdate: BirthdateProps = { day: "", month: "", year: "" };

    if (updatedUser?.data?.birthdate) {
      const { year, month, day } = DateTime.fromFormat(updatedUser?.data?.birthdate as string, "yyyy-MM-dd");

      updatedBirthdate = castObject({ year, month, day }, "string") as unknown as BirthdateProps;
    }

    reset(
      {
        ...(updatedUser as unknown as ProfileProps),

        birthdate: updatedBirthdate,
        gender: shouldReplaceGender ? GenderTypes.CUSTOM : updatedUser?.data?.gender,
        custom_gender: shouldReplaceGender ? (updatedUser?.data?.gender as any as GenderTypes) : undefined,
      },
      {}
    );
  };

  return (
    <Flex direction="column" gridRowGap="8" width="inherit">
      <Alert status={data ? "success" : "error"} ref={alertRef} autoDisplay={false}>
        {error?.message ?? (data ? "Alterações salvas!" : undefined)}
      </Alert>
      <Flex direction="column" alignItems="start" gridRowGap="2">
        <Box textStyle="h5" color="gray.900">
          {translate("profile@title")}
        </Box>
        <Box textStyle="body2" color="gray.700">
          {translate("profile@subtitle")}
        </Box>
      </Flex>
      <FormControl
        as="form"
        display="flex"
        flexDirection="column"
        gridRowGap="8"
        width="inherit"
        isInvalid={formState.isDirty}
      >
        <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
          <Flex direction="column" width="100%" alignItems="flex-start">
            <FormLabel htmlFor="name" textStyle="label.primay" color="black">
              {translate("profile@form-name-label")}
            </FormLabel>
            <Input type="text" id="name" isInvalid={!!formState.errors.name} required {...register("name")} />
            {formState.errors.name && (
              <FormErrorMessage>
                {translate(formState.errors.name.message as "profile@validation-gender-required")}
              </FormErrorMessage>
            )}
          </Flex>

          <Flex direction="column" width="100%">
            <FormLabel htmlFor="birthdate" textStyle="label.primary" color="black">
              {translate("profile@form-birthdate-label")}
            </FormLabel>
            <Grid templateColumns="2fr 5fr 3fr" gridColumnGap="4" id="birthdate">
              <Input
                placeholder="Dia"
                maxLength={2}
                {...register("birthdate.day")}
                isInvalid={!!formState.errors.birthdate?.day}
                required
              />
              <Controller
                control={control}
                name="birthdate.month"
                render={({ field: { onChange, ...field } }) => (
                  <Select placeholder="Mês" onChangeValue={onChange} {...field}>
                    <Option value="1">01 - Janeiro</Option>
                    <Option value="2">02 - Fevereiro</Option>
                    <Option value="3">03 - Março</Option>
                    <Option value="4">04 - Abril</Option>
                    <Option value="5">05 - Maio</Option>
                    <Option value="6">06 - Junho</Option>
                    <Option value="7">07 - Julho</Option>
                    <Option value="8">08 - Agosto</Option>
                    <Option value="9">09 - Setembro</Option>
                    <Option value="10">10 - Outubro</Option>
                    <Option value="11">11 - Novembro</Option>
                    <Option value="12">12 - Dezembro</Option>
                  </Select>
                )}
              />
              <Input
                placeholder="Ano"
                maxLength={4}
                {...register("birthdate.year")}
                isInvalid={!!formState.errors.birthdate?.year}
                required
              />
            </Grid>
            {formState.errors.birthdate &&
              Object.values(formState.errors.birthdate).map(({ message }) => (
                <FormErrorMessage key={message}>{message}</FormErrorMessage>
              ))}
          </Flex>

          <SimpleGrid width="100%" spacing={4} {...columns}>
            <Gender isCustomGender={isCustomGender} control={control} />
            <Flex direction="column" width="inherit">
              <FormLabel htmlFor="native_language" textStyle="label.primary" color="black">
                {translate("profile@form-native-lenguage-label")}
              </FormLabel>
              <Skeleton isLoaded={!!languages.data}>
                <Controller
                  control={control}
                  name="native_language"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Select
                      id="native_language"
                      data-testid="native_language"
                      onChangeValue={onChange}
                      searchable={translate("profile@form-native-lenguage-select-placeholder")}
                      value={value as StringOrNumber}
                      {...field}
                    >
                      {languages?.data?.results?.map(({ code, name }) => (
                        <Option key={code} value={code}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  )}
                />
              </Skeleton>
            </Flex>
          </SimpleGrid>
        </Flex>
        <Box>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isDisabled={
              isBeeingFilled
                ? !(formState.isValid && formState.isDirty && isFilled)
                : !(formState.isValid && formState.isDirty)
            }
            isLoading={loading}
            minWidth="24"
          >
            {translate("profile@form-save-button")}
          </Button>
        </Box>
      </FormControl>
    </Flex>
  );
}

export default Profile;
