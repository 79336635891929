import { Flex } from "@chakra-ui/react";

import { useNamespace } from "~/hooks";

import { Phrase, Step } from "..";

function StepOne() {
  const { translate } = useNamespace("outdoor");

  return (
    <Flex direction="column" gap={["2", "4"]} mt={["0", "4.375rem"]} align="start">
      <Phrase>{translate("outdoor@signUp-step2")}</Phrase>
      <Step>{translate("outdoor@step-2")}</Step>
    </Flex>
  );
}

export default StepOne;
