import { Box, Flex, Grid, GridItem, GridProps, Image, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { Outlet, useOutletContext } from "react-router-dom";

import { ResponseProps as UserProps, useUser } from "~/api/users/retrieve";
import { useAuth, useQueryParam } from "~/hooks";
import useAmplitude from "~/hooks/useAmplitude";
import useNamespace from "~/hooks/useNamespace";
import theme from "~/theme";
import { LoadingPage, SupportButton } from "~/theme/components";
import { AddressBook, Home, Password, User } from "~/theme/icons";

import { Header, Navigation, NavItem, RightSideBar } from "./components";

const navItems = [
  { Icon: User, title: "pageLayout@config-personal-data-title", to: "/profile" },
  { Icon: Password, title: "pageLayout@config-password-title", to: "/password" },
  { Icon: Home, title: "pageLayout@config-address-title", to: "/address" },
  { Icon: AddressBook, title: "pageLayout@config-contact-title", to: "/contact" },
];

function Layout() {
  const auth = useAuth();
  const params = useQueryParam("product");
  const { data } = useUser();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { translate } = useNamespace("pageLayout");

  useAmplitude({ id: data?.id });

  const responsive = useBreakpointValue<GridProps>([
    {
      px: "4",
      pb: "8",
      pt: "28",
      gridRowGap: "6",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "0 auto 1fr",
      gridTemplateAreas: `
				'navigation'
				'profile'
				'content'
			`,
    },
    {
      px: "auto",
      pb: "auto",
      mt: "16",
      gridColumnGap: "6",
      gridTemplateColumns: `${theme.sizes[80]} ${theme.sizes.xl} ${theme.sizes[56]}`,
      gridTemplateRows: "1fr",
      gridTemplateAreas: `
				'navigation content profile'
			`,
    },
  ]);

  // If the user is authenticated and the product is defined, show the loader to redirect the user to the product
  if (auth.isUserIn && params.product) {
    return <LoadingPage />;
  }

  return (
    <Flex bgColor="gray.100" position="relative" width="100%" minHeight="100vh" justifyContent="center">
      <Header onOpen={onOpen} user={data} />
      <Grid
        justifyContent="center"
        position="relative"
        zIndex="docked"
        sx={{
          "& .card": {
            borderRadius: "2xl",
            bgColor: "white",
            borderWidth: "0.0625rem",
            borderColor: "purple.50",
            borderStyle: "solid",
          },
        }}
        {...responsive}
      >
        <GridItem gridArea="navigation">
          <Navigation isOpen={isOpen} onClose={onClose}>
            {(isMobileDevice) => {
              const defaultNav = navItems.map(({ title, ...props }) => (
                <NavItem
                  tabIndex={0}
                  key={props.to}
                  isMobile={isMobileDevice}
                  title={translate(title as "pageLayout@config-title")}
                  {...props}
                />
              ));

              return (
                <>
                  {isMobileDevice ? (
                    <>{defaultNav}</>
                  ) : (
                    <>
                      <Box px="8" textStyle="h6">
                        {translate("pageLayout@config-title")}
                      </Box>
                      <Flex width="inherit" direction="column">
                        {defaultNav}
                      </Flex>
                    </>
                  )}
                </>
              );
            }}
          </Navigation>
        </GridItem>
        <GridItem gridArea="content">
          <Flex display="flex" className="card" padding="8" mt="12" width="100%">
            <Outlet context={{ auth, user: data }} />
          </Flex>
        </GridItem>
        <GridItem gridArea="profile">
          <RightSideBar user={data} />
        </GridItem>
      </Grid>
      <Image inset="0 0 auto auto" position="fixed" width="sm" height="3xl" src="/images/fluency.svg" />
      <SupportButton />
    </Flex>
  );
}

export function useSignedIn() {
  const response = useOutletContext<{ auth: ReturnType<typeof useAuth>; user: UserProps }>();

  return response;
}

export default Layout;
