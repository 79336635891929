import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import type { CookieSetOptions } from "universal-cookie/cjs/types";

import store from "~/context";
import slice, { SessionProps } from "~/context/features/auth";

export interface RequestProps {
  refresh_token: string;
}

export interface ResponseProps {
  session: SessionProps;
}

const ACCOUNTS_SESSION = "@Accounts:jwt_token";
const ACCOUNTS_REFRESH = "@Accounts:refresh_token";
const ACCOUNTS_EMAIL = "@Accounts:email";

const getCookieOption = (maxAge: number = 30 * 24 * 60 * 60) => {
  const defaultOptions: CookieSetOptions = {
    maxAge: maxAge,
    sameSite: "lax",
    domain: import.meta.env.VITE_APP_DOMAIN as string,
    secure: import.meta.env.PROD,
    path: "/",
  };

  return defaultOptions;
};

const setSession = (
  accessToken: string,
  refreshToken?: string,
  idToken?: string,
  accessMaxAge: number = 1 * 24 * 60 * 60
) => {
  const cookies = new Cookies();

  const sessionCookieOption = getCookieOption(accessMaxAge);

  cookies.set(ACCOUNTS_SESSION, accessToken, sessionCookieOption);

  if (idToken) {
    const { email } = jwtDecode<Record<string, string>>(idToken);

    if (email) {
      const emailCookieOption = getCookieOption();

      cookies.set(ACCOUNTS_EMAIL, email, emailCookieOption);
    }
  }

  if (refreshToken) {
    const refreshCookieOption = getCookieOption();

    cookies.set(ACCOUNTS_REFRESH, refreshToken, refreshCookieOption);
  }
};

const getSession = () => {
  const cookies = new Cookies();
  const access = cookies.get(ACCOUNTS_SESSION);
  const refresh = cookies.get(ACCOUNTS_REFRESH);

  return { authorization: access, refresh: refresh };
};

const destroySession = () => {
  const cookies = new Cookies();
  const options = getCookieOption();

  cookies.remove(ACCOUNTS_EMAIL, options);
  cookies.remove(ACCOUNTS_REFRESH, options);
  cookies.remove(ACCOUNTS_SESSION, options);
};

const revalidate = async () => {
  const { authorization, refresh } = getSession();

  if (!authorization && !refresh) throw new Error("User is not authenticated");
  const { auth } = store.getState();
  const refreshApi = await import("~/api/auth/refresh").then((refresh) => refresh.default);
  const [response] = await refreshApi.post({
    refresh_token: auth.session.refresh_token,
    email: auth.authenticate.email,
  });

  if (response?.session) {
    setSession(response.session.access_token, undefined, undefined, response.session.expires_in);

    const action = slice.actions.setSession({
      ...response.session,
      refresh_token: auth.session.refresh_token,
    });

    store.dispatch(action);
  }

  return response?.session.access_token;
};

const componentWillMount = async () => {
  try {
    await revalidate();
  } catch (exception) {
    store.dispatch(slice.actions.signOut());
  }
};

const useSession = () => getSession();

export { revalidate, componentWillMount, getSession, setSession, destroySession, useSession };
