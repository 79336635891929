/* eslint-disable no-console */
import { init, setUserId, track } from "@amplitude/analytics-browser";

interface AmplitudeEventParams {
  event: EventType;
  feature: FeatureType;
  selectedProduct?: ProductType | null;
}

export enum EventType {
  LOGIN_STARTED = "login_started",
  EMAIL_ADDED = "email_added",
  VERIFICATION_CODE_ADDED = "verification_code_added",
  INPUT_ERROR_OCCURRED = "input_error_occurred",
  LOGIN_COMPLETED = "login_completed",
  PRODUCT_SELECTED = "product_selected",
}

export enum PagetType {
  GROUP_CLASS_RECURRENCY_BOOKING_CONFIRMATION = "group_class_recurrency_booking_confirmation",
  GROUP_CLASS_COMPLETED = "group_class_completed",
}

export enum MadeByType {
  STUDENT = "student",
}

export enum FeatureType {
  PRODUCT_HUB = "product_hub",
  LOGIN = "login",
}

export enum ProductType {
  ACCOUNTS = "accounts",
  TALKS = "talks",
  MEMHACK = "memhack",
  ACADEMY = "academy",
}

export const setupAmplitude = () => {
  const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY as string;

  if (!import.meta.env.PROD) return;

  if (!AMPLITUDE_API_KEY) {
    console.warn("Amplitude API key is missing");

    return;
  }

  init(AMPLITUDE_API_KEY, {
    autocapture: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },
  });
};

export const identifyUser = (userId?: string | null) => {
  if (!userId || !import.meta.env.PROD) return;

  setUserId(userId);
};

export function sendEvent({ event, feature, selectedProduct }: AmplitudeEventParams) {
  if (!import.meta.env.PROD) {
    console.log("Amplitude event", { event, feature });

    return;
  }

  const params: Record<string, string> = {
    made_by: MadeByType.STUDENT,
    feature: feature,
    product: ProductType.ACCOUNTS,
  };

  if (selectedProduct) {
    params["selected_product"] = selectedProduct;
  }

  track(event, params);
}
