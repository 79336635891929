const override = (value: string, replace: string, until?: string) => {
  if (until) {
    const index = value.indexOf(until) % value.slice(1).length;
    const holders = new Array(index).fill(replace).join("");

    return holders + value.slice(index);
  }

  return new Array(value.length).fill(replace).join("");
};

export const email = (email: string) => {
  const [name, domain] = email.split("@");
  const [firstChar, lastChar] = [name, domain].map((current) => current.charAt(0));

  return firstChar + override(name.slice(1), "*") + "@" + lastChar + override(domain.slice(), "*", ".");
};

export const phone = (phone: string) => {
  const firstPath = phone.slice(0, phone.indexOf(")") + 3);
  const lastPath = phone[phone.length - 1];

  return firstPath + override(phone.slice(firstPath.length, phone.length - 1).trimStart(), "*") + lastPath;
};
