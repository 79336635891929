import { AppComponent } from "@fluencyacademy/core-components-web";

import { useTranslations } from "../hooks/use-translations";
import { HomeProduct } from "../types/product";
import ProductContent from "./product-content";
import ProductTag from "./product-tag";

const ProductTalksContent: AppComponent = () => {
  const t = useTranslations("productsHub");

  return (
    <ProductContent
      product={HomeProduct.TALKS}
      title={t("talks_product.content_title")}
      description={t("talks_product.description")}
      backgroundColor="bg-brand-primary-talks-600"
      mainImgSrc={"/images/talks_content.png"}
      extraHeader={<ProductTag label={t("talks_product.tag")} backgroundColor="bg-brand-primary-talks-700" />}
    />
  );
};

export default ProductTalksContent;
