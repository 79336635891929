import { theme, Theme, ButtonProps, PinInputProps, AlertProps, CSSObject } from "@chakra-ui/react";
import { SystemStyleFunction } from "@chakra-ui/theme-tools";

import * as colors from "~/theme/colors";

import { textStyles } from "../styles";

const components: Theme["components"] = {
  Button: {
    baseStyle: {
      _disabled: {
        opacity: 0.25,
      },
    },
    sizes: {
      md: ({ variant, colorScheme }: ButtonProps) => {
        const defaults: ButtonProps = {
          ...textStyles.button.primary,
        };

        if (variant === "terciary") {
          return {
            ...defaults,
            minWidth: "auto",
            px: "0",
          };
        }

        return {
          ...defaults,
          borderColor: `${colorScheme}.300`,
          borderWidth: "0.125rem",
          borderRadius: "full",
          height: "12",
        };
      },
    } as Record<string, CSSObject>,
    variants: {
      primary: ({ colorScheme }: ButtonProps) => ({
        bgColor: `${colors[colorScheme as keyof typeof colors][300]} !important`,
        color: "white",
      }),
      secondary: ({ colorScheme }: ButtonProps) => ({
        bgColor: "white",
        color: `${colorScheme}.300`,
        border: "none",
      }),
      terciary: ({ colorScheme }: ButtonProps) => ({
        color: `${colorScheme}.300`,
        height: "auto",
        borderRadius: "none",
      }),
    } as unknown as { [key: string]: SystemStyleFunction } & typeof theme.components.Button.variants,
    defaultProps: {
      variant: "primary",
      colorScheme: "purple",
      size: "md",
    },
  },
  Input: {
    baseStyle: {
      field: {
        borderWidth: "0.125rem",
      },
    },
    variants: {
      outline: ({ colorScheme }) => ({
        field: {
          borderRadius: "lg",
          height: "12",
          _focus: {
            borderColor: `${colorScheme}.300`,
          },
          _invalid: {
            borderColor: "error.500",
            boxShadow: "none",
          },
          _disabled: {
            bgColor: "gray.100",
            opacity: 0.8,
          },
        },
      }),
    } as Theme["components"]["Input"]["variants"],
    defaultProps: {
      size: "md",
      variant: "outline",
      colorScheme: "purple",
    },
  } as unknown as Theme["components"]["Input"],
  PinInput: {
    variants: {
      outline: ({ colorScheme }: PinInputProps) => ({
        borderColor: `${colorScheme}.300`,
      }),
    },
    defaultProps: {
      size: "lg",
      variant: "outline",
    },
  } as unknown as Theme["components"]["PinInput"],
  Skeleton: {
    baseStyle: () => ({
      borderRadius: "lg",
    }),
  },
  Alert: {
    baseStyle: {
      container: {
        borderRadius: "lg",
      },
    },
    variants: {
      "left-accent": ({ status, colorScheme }: AlertProps) => ({
        container: {
          bgColor: `${status ?? colorScheme}.50`,
          textStyle: "body2",
          color: "gray.900",
          borderInlineStartWidth: "0.5rem",
          borderColor: `${status ?? colorScheme}.600`,
        },
        icon: {
          color: `${status ?? colorScheme}.600`,
        },
      }),
    },
    defaultProps: {
      variant: "left-accent",
      colorScheme: "purple",
    },
  },
} as unknown as Theme["components"];

export { default as Password } from "./Password";
export * from "./Password";

export { default as Scrollbar } from "./Scrollbar";
export * from "./Scrollbar";

export { default as Select } from "./Select";
export * from "./Select";

export { default as Phone } from "./Phone";
export * from "./Phone";

export { default as Photo } from "./Photo";
export * from "./Photo";

export { default as LoadingPage } from "./LoadingPage";
export * from "./LoadingPage";

export { default as MotionBox } from "./MotionBox";
export * from "./MotionBox";

export { default as Alert } from "./Alert";
export * from "./Alert";

export { default as LanguageMenu } from "./LanguageMenu";
export * from "./LanguageMenu";

export { default as Gender } from "./Gender";
export * from "./Gender";

export { default as SupportButton } from "./SupportButton";
export * from "./SupportButton";

export default components;
