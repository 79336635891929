import { MouseEventHandler, RefObject, useEffect, useRef, useState } from "react";

import {
  Modal,
  ModalProps as ChakraModalProps,
  ModalOverlay,
  ModalContent,
  VStack,
  Box,
  FormLabel,
  Button,
  Grid,
  useDisclosure,
  Flex,
  PinInput,
  PinInputField,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useElapsedTime } from "use-elapsed-time";
import * as Yup from "yup";

import { useUpdateUser } from "~/api/users/update";
import { useSendPhoneCode } from "~/api/validation/sendPhone";
import { useValidatePhoneCode } from "~/api/validation/verifyPhone";
import { useNamespace } from "~/hooks";
import { Alert, ImperativeAlertProps, Phone, PhoneInputProps } from "~/theme/components";
import { ArrowLeft } from "~/theme/icons";
import * as Obfuscate from "~/utils/obfuscate";

interface ChangePhoneModalProps extends Omit<ChakraModalProps, "children"> {
  phone: string;
  resetForm: (code: string, phone: string) => void;
  pageAlertRef: RefObject<ImperativeAlertProps>;
}

interface PhoneProps {
  phone: PhoneInputProps;
}

const schema = Yup.object({
  phone: Yup.object({
    number: Yup.string()
      .test("phone-validation", "Número de telefone incorreto", (password) => !/_/.test(password as string))
      .nullable(),
  }),
});

const ChangePhoneModal: React.FC<ChangePhoneModalProps> = ({ phone, resetForm, pageAlertRef, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sendCode, { loading: isSending, error: sendingError }] = useSendPhoneCode();
  const [validateCode, { loading, error: validatingError }] = useValidatePhoneCode();
  const [updateUser] = useUpdateUser();
  const [code, setCode] = useState("");
  const { elapsedTime, reset: resetTime } = useElapsedTime({ isPlaying: true, duration: 30, updateInterval: 1 });
  const seconds = Math.round(30 - elapsedTime);
  const alertRef = useRef<ImperativeAlertProps>(null);
  const { translate } = useNamespace("contact");

  const { control, reset, handleSubmit, getValues, formState } = useForm<PhoneProps>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      get phone() {
        if (!phone) return {};
        const regExp = new RegExp(/^(\d.+)\s(\(.*\))\s(\d.*)/);
        const phoneNumber = regExp.exec(phone);

        if (!phoneNumber?.length) return {};

        const [_, code, ddd, number] = phoneNumber;

        return {
          code,
          number: `${ddd} ${number}`,
        };
      },
    },
  });
  const phoneNumber = `${getValues("phone.code")} ${getValues("phone.number")}`;

  const handleClose = () => {
    onClose();
    reset();
    props.onClose();
  };

  const handleSend: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();

    const response = await sendCode({ phone_number: phoneNumber });

    if (response?.data.created) {
      resetTime();
      onOpen();
    }
  };

  const handleResend = async () => {
    const response = await sendCode({ phone_number: phoneNumber });

    if (response?.data.created) {
      setCode("");
      resetTime();
    }
  };

  const onSubmit = async () => {
    const response = await validateCode({ code });

    if (response?.data?.active) {
      const code = getValues("phone.code");
      const number = getValues("phone.number");

      await updateUser({ phone_number: `${code} ${number}` });
      resetForm(code as string, number as string);

      handleClose();
      pageAlertRef.current?.turnOnAlert();
    }
  };

  useEffect(() => {
    alertRef.current?.turnOnAlert();
  }, [sendingError, validatingError]);

  return (
    <Modal {...props} onClose={handleClose}>
      <ModalOverlay backgroundColor="rgba(0, 0, 0, .8)" />
      <ModalContent
        alignSelf="center"
        justifySelf="center"
        padding={["8", "12"]}
        margin={["4", "0"]}
        borderRadius="1rem"
      >
        <VStack as={FormControl} isInvalid={formState.isDirty} gap="8">
          <Alert status="error" ref={alertRef} autoDisplay={false}>
            {validatingError?.message ?? sendingError?.message}
          </Alert>
          <VStack align="start" gap="3">
            {isOpen && (
              <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={onClose}>
                {translate("contact@modal-back")}
              </Button>
            )}
            <Box textStyle="h5" color="gray.900" fontWeight="bold">
              {translate("contact@modal-title")}
            </Box>
            {isOpen ? (
              <Box textStyle="body2" color="gray.700" gap="2">
                {translate("contact@modal-description-2", { phone: Obfuscate.phone(phoneNumber) })}
              </Box>
            ) : (
              <Box textStyle="body2" color="gray.700" gap="2">
                {translate("contact@modal-description-1")}
              </Box>
            )}
          </VStack>
          {isOpen ? (
            <Flex justifyContent="space-between" width="100%">
              <PinInput
                placeholder=""
                onChange={(value) => setCode(value.toUpperCase())}
                value={code}
                type="alphanumeric"
                otp
              >
                <PinInputField data-testid="pin" required />
                <PinInputField data-testid="pin" required />
                <PinInputField data-testid="pin" required />
                <PinInputField data-testid="pin" required />
                <PinInputField data-testid="pin" required />
                <PinInputField data-testid="pin" required />
              </PinInput>
            </Flex>
          ) : (
            <VStack width="inherit" alignItems="flex-start">
              <FormLabel htmlFor="phone_number" textStyle="label.primay" color="black">
                {translate("contact@modal-label-phone")}
              </FormLabel>
              <Controller
                control={control}
                name="phone"
                rules={{ required: true }}
                render={({ formState, field }) => (
                  <>
                    <Phone isInvalid={!!formState.errors.phone} {...field} />
                    {formState.errors.phone?.number?.message && (
                      <>
                        <FormErrorMessage>{formState.errors.phone.number.message}</FormErrorMessage>
                      </>
                    )}
                  </>
                )}
              />
            </VStack>
          )}
          {isOpen ? (
            <Grid templateColumns="1fr 1fr" width="100%">
              <Button
                onClick={handleResend}
                isDisabled={!(elapsedTime === 30 || seconds === 0)}
                type="button"
                variant="ghost"
                data-type="primary"
                border="none"
              >
                {translate("contact@modal-button-resend")} {seconds ? `${seconds}s` : ""}
              </Button>
              <Button
                onClick={handleSubmit(onSubmit)}
                isDisabled={code.length !== 6}
                type="submit"
                data-type="primary"
                isLoading={loading || isSending}
              >
                {translate("contact@modal-button-confirm")}
              </Button>
            </Grid>
          ) : (
            <Grid templateColumns="4fr 6fr" width="100%">
              <Button onClick={handleClose} type="button" variant="ghost" data-type="primary" border="none">
                {translate("contact@modal-button-cancel")}
              </Button>
              <Button
                onClick={handleSend}
                type="submit"
                data-type="primary"
                isDisabled={!!formState.errors.phone?.number}
                isLoading={isSending}
              >
                {translate("contact@modal-button-continue")}
              </Button>
            </Grid>
          )}
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default ChangePhoneModal;
