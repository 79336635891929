import { createIcon } from "@chakra-ui/icons";

const Visible = createIcon({
  displayName: "Visible",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 6C10.0572 6.00105 8.15969 6.5739 6.55449 7.64398C4.94928 8.71407 3.71071 10.2318 3 11.9997C3.70967 13.7683 4.94797 15.2868 6.55339 16.3572C8.15882 17.4275 10.0569 18 12 18C13.9431 18 15.8412 17.4275 17.4466 16.3572C19.052 15.2868 20.2903 13.7683 21 11.9997C20.2893 10.2318 19.0507 8.71407 17.4455 7.64398C15.8403 6.5739 13.9428 6.00105 12 6ZM12 15.9995C11.1909 15.9995 10.4 15.7649 9.72721 15.3254C9.05447 14.8859 8.53012 14.2612 8.22049 13.5304C7.91086 12.7995 7.82985 11.9953 7.9877 11.2194C8.14555 10.4435 8.53517 9.7308 9.10729 9.17142C9.67941 8.61204 10.4083 8.23109 11.2019 8.07676C11.9955 7.92243 12.818 8.00163 13.5655 8.30437C14.313 8.60711 14.952 9.11977 15.4015 9.77754C15.851 10.4353 16.0909 11.2086 16.0909 11.9997C16.0909 13.0605 15.6599 14.0779 14.8927 14.828C14.1255 15.5781 13.085 15.9995 12 15.9995ZM12 9.59983C11.5145 9.59983 11.04 9.74058 10.6363 10.0043C10.2327 10.268 9.91807 10.6428 9.7323 11.0813C9.54652 11.5198 9.49791 12.0024 9.59262 12.4679C9.68733 12.9334 9.9211 13.3611 10.2644 13.6967C10.6076 14.0323 11.045 14.2609 11.5211 14.3535C11.9973 14.4461 12.4908 14.3986 12.9393 14.2169C13.3878 14.0353 13.7712 13.7277 14.0409 13.333C14.3106 12.9384 14.4545 12.4744 14.4545 11.9997C14.4545 11.3632 14.1959 10.7528 13.7356 10.3027C13.2753 9.85267 12.651 9.59983 12 9.59983Z"
      fill="currentColor"
    />
  ),
});

export default Visible;
