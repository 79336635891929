import axios, { AxiosError, AxiosResponseTransformer } from "axios";

import store from "~/context";
import auth from "~/context/features/auth";

import { getSession, revalidate } from "./session";

const outputErrors: AxiosResponseTransformer = (data, headers?) => {
  if (headers && /application\/json/.test(headers["content-type"])) {
    const response = JSON.parse(data);

    if (Array.isArray(response?.errors)) {
      throw new Error(response.errors.flatMap((error: ErrorsProps) => error?.errors).join("\n"));
    } else if (response?.errors?.details) {
      throw new Error(response?.errors?.details);
    } else if ("detail" in response) {
      throw new Error(response?.detail);
    }

    return response;
  }

  return data;
};

export const bifrostHttp = axios.create({
  baseURL: `https://bifrost${import.meta.env.VITE_APP_DOMAIN}` as string,
  transformResponse: [outputErrors],
});

bifrostHttp.interceptors.request.use((config) => {
  const { authorization } = getSession();

  return {
    ...config,
    headers: {
      ...config.headers,
      ...(authorization ? { Authorization: `Bearer ${authorization}` } : {}),
    },
  };
});

bifrostHttp.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const { authorization, refresh } = getSession();
    const status =
      !error.response && error.message.match(/Credenciais de autenticação incorretas.|Invalid token./)
        ? 401
        : error.response?.status;

    if (status === 401 && !authorization && !refresh) {
      store.dispatch(auth.actions.signOut());
      window.location.href = "/";
    }

    if (status === 401 && (authorization || refresh)) {
      try {
        const accessToken = await revalidate();

        return axios.request({
          ...error.config,
          headers: {
            ...error.config?.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } catch (exception) {
        store.dispatch(auth.actions.signOut());
        window.location.href = "/";

        throw exception;
      }
    }

    return Promise.reject(error);
  }
);

export default bifrostHttp;
