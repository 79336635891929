import { Link } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { ResponseProps } from "~/api/users/retrieve";
import SimpleLanguageMenu from "~/theme/components/SimpleLanguageMenu";
import Company from "~/theme/icons/fluency/Company";

import AppearDiv from "./appear-div";
import NavbarTitle from "./navbar-title";
import SupportButton from "./support-button";
import { UserMenu } from "./user-menu";

export type NavbarProps = {
  user?: ResponseProps;
};

const Navbar = ({ user }: NavbarProps) => (
  <nav className="flex flex-col w-full justify-center items-center md:h-[88px]">
    <div className="flex flex-row justify-between w-full px-4 items-center transition-all h-14 sm:h-20 max-w-[1800px] md:px-[60px] z-30">
      <AppearDiv delay={0} className="flex flex-row items-center">
        <Link
          as={NavLink}
          to="/"
          sx={{
            "&:link, &:visited, &:hover, &:active": {
              color: "white",
            },
          }}
        >
          <Company width="28" height="9" />
        </Link>
        <div className="hidden md:flex md:w-[160px]" />
      </AppearDiv>
      <AppearDiv delay={0.5} className="hidden md:flex">
        <NavbarTitle />
      </AppearDiv>
      <div className="flex flex-row gap-4 items-center justify-center">
        <AppearDiv delay={0.1} className="z-30 ml-1">
          <div className="!hidden md:!flex">
            <SupportButton className="hover:scale-110 transition-transform" />
          </div>
        </AppearDiv>
        <SimpleLanguageMenu />
        <AppearDiv delay={0.1} className="z-30 ml-1">
          <UserMenu user={user} />
        </AppearDiv>
      </div>
    </div>
    <AppearDiv delay={0.1} className="flex md:hidden justify-center items-center">
      <NavbarTitle />
    </AppearDiv>
  </nav>
);

export { Navbar };
