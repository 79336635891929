import { ReactNode } from "react";

export enum HomeProduct {
  COURSES,
  MEMORIZATION,
  TALKS,
}

export type HomeProductItem = {
  product: HomeProduct;
  title: ReactNode;
  content: ReactNode;
};
