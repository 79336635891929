import useMutate from "~/hooks/useMutateApi";

import { ResponseProps } from "./retrieve";

export interface RequestProps {
  name: string;
  email: string;
  password: string;
  source: string;
}

const useUserSignUp = () => useMutate<ResponseProps, RequestProps>("/users/create-account/");

export { useUserSignUp };
