import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import completeNewPassword, { RequestProps as CompletePasswordProps } from "~/api/auth/completePassword";
import signOut from "~/api/auth/signOut";
import signIn, { AuthStateTypes, RequestProps as SignInRequestProps } from "~/api/auth/singIn";
import verify, { RequestProps as VerifyRequestProps } from "~/api/validation/verifyUser";
import { destroySession, getSession, setSession } from "~/services/session";
import getVerification from "~/utils/getVerifications";

export enum Cookies {
  SESSION = "@Accounts:jwt_token",
}

export interface UserAttributesProps {
  email_verified: string;
  name: string;
  email: string;
}

export interface ProvisionalProps {
  userAttributes: UserAttributesProps;
  session_token: string;
}

export interface SessionProps {
  access_token: string;
  refresh_token: string;
  id_token: string;
  expires_in: number;
}

export interface AuthenticateProps {
  email?: string;
  password?: string;
}

export enum ValidationTypes {
  EMAIL = "EMAIL",
  PHONE = "PHONE_NUMBER",
}

export interface StateProps {
  session: SessionProps | null;
  loading: boolean;
  error: Error | null;
  validation: ValidationTypes | null;
  authenticate?: AuthenticateProps | null;
  authState?: AuthStateTypes | null;
  provisional?: ProvisionalProps;
}

const initialState: StateProps = {
  session: null,
  loading: false,
  error: null,
  authState: null,
  authenticate: null,
  validation: null,
};

export const performSignIn = createAsyncThunk(
  "auth/signIn",
  async (value: SignInRequestProps, { signal, rejectWithValue }) => {
    try {
      const [data] = await signIn.post(value, { signal });

      return { ...data, email: value.email };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const performSignOut = createAsyncThunk("auth/signOut", async (_, { rejectWithValue }) => {
  try {
    const { authorization, refresh } = getSession();

    await signOut(authorization, refresh);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const performCompletePassword = createAsyncThunk(
  "auth/completePassword",
  async (value: CompletePasswordProps, { signal, rejectWithValue }) => {
    try {
      const [data] = await completeNewPassword.post(value, { signal });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const performVerify = createAsyncThunk(
  "auth/verify",
  async (value: VerifyRequestProps, { signal, rejectWithValue }) => {
    try {
      const [response] = await verify.get({ email: value.email }, { signal });

      const validation = getVerification(response.verifications);

      return { validation };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionProps>) => {
      state.session = action.payload;

      setSession(
        action.payload.access_token,
        action.payload.refresh_token,
        action.payload.id_token,
        action.payload.expires_in
      );
    },
    setAuthState: (state, action: PayloadAction<AuthStateTypes | null>) => {
      state.authState = action.payload;
    },
    setAuthenticate: (state, action: PayloadAction<AuthenticateProps | null>) => {
      state.authenticate = action.payload;
    },
    setProvisional: (state, action: PayloadAction<ProvisionalProps>) => {
      state.provisional = action.payload;
    },
    signOut: (state) => {
      state.error = null;
      state.loading = false;
      state.session = null;
      state.authenticate = null;

      destroySession();
    },
  },
  extraReducers(builder) {
    builder
      .addCase(performSignIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performSignIn.rejected, (state, action) => {
        state.error = action.payload as Error;
        state.loading = false;
      })
      .addCase(performSignIn.fulfilled, (state, action) => {
        switch (action.payload.auth_state) {
          case "AUTHENTICATED":
            slice.caseReducers.setSession(state, { ...action, payload: action.payload.session });
            state.authState = action.payload.auth_state;
            state.authenticate = { email: action.payload.email, password: undefined };
            state.loading = true;
            break;
          case "NEW_PASSWORD_REQUIRED":
            slice.caseReducers.setProvisional(state, {
              ...action,
              payload: {
                userAttributes: action.payload?.user_attributes as UserAttributesProps,
                session_token: action.payload?.session_token as string,
              },
            });
            state.authState = action.payload.auth_state;
            state.loading = false;
            break;
          default:
            state.error = null;
            state.loading = false;
            break;
        }
      })
      .addCase(performSignOut.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performSignOut.rejected, (state, { payload }) => {
        state.error = payload as Error;
        state.loading = false;
        slice.caseReducers.signOut(state);
      })
      .addCase(performSignOut.fulfilled, (state) => {
        state.loading = false;
        slice.caseReducers.signOut(state);
      })
      .addCase(performCompletePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performCompletePassword.rejected, (state, { payload }) => {
        state.error = payload as Error;
        state.loading = false;
      })
      .addCase(performCompletePassword.fulfilled, (state, { payload }) => {
        // state.session = payload.session;
        state.authState = payload.auth_state;
        state.loading = false;
      })
      .addCase(performVerify.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performVerify.rejected, (state, { payload }) => {
        state.error = payload as Error;
        state.loading = false;
      })
      .addCase(performVerify.fulfilled, (state, { payload }) => {
        state.validation = payload.validation;
        if (payload.validation) state.loading = false;
      });
  },
});

export default slice;
