import { createIcon } from "@chakra-ui/icons";

const Company = createIcon({
  displayName: "Company",
  viewBox: "0 0 98 33",
  path: (
    <>
      <path
        d="M47.0105 5.85049C47.0105 2.53256 49.3341 0.00144153 52.9416 0C56.5492 0.00143211 58.8727 2.53255 58.8727 5.85049V21.5742H55.1399V5.85049C55.1399 4.6063 54.1858 3.6523 52.9416 3.6523C51.6973 3.6523 50.7433 4.6063 50.7433 5.85049V21.5742H47.0105V5.85049Z"
        fill="currentColor"
      />
      <path
        d="M15.0671 0.370483V16.0973C15.0671 17.0544 15.8451 17.8324 16.8022 17.8324H19.6212V21.5742H16.8022C13.7827 21.5742 11.325 19.1167 11.325 16.0973V0.370483H15.0671Z"
        fill="currentColor"
      />
      <path
        d="M27.624 18.2399C28.8559 18.2399 29.6803 17.2952 29.6803 16.0603H29.6864V0.370483H33.3884V15.8966C33.3884 19.5582 31.1252 21.9447 27.6271 21.9447C24.1289 21.9447 21.8657 19.5582 21.8657 15.8966V0.370483H25.5677V16.0603C25.5677 17.2921 26.392 18.2399 27.624 18.2399Z"
        fill="currentColor"
      />
      <path
        d="M39.3751 15.0137C39.3751 13.4052 40.4063 11.9202 42.1384 11.9202H44.2008V8.20612H41.5209C40.1994 8.20612 39.3751 7.33857 39.3751 6.14378C39.3751 4.94898 40.1994 4.08145 41.5209 4.08145H44.2008V0.370483H41.3943C38.3407 0.370483 35.6175 2.80329 35.6175 6.0635C35.6175 8.16598 36.7723 9.61085 38.2574 10.2715C36.6488 11.0959 35.6175 12.9946 35.6175 15.1402C35.6175 19.0179 38.5877 21.5742 42.2186 21.5742H44.241V17.9034H42.1384C40.6934 17.9034 39.3751 16.6252 39.3751 15.0168V15.0137Z"
        fill="currentColor"
      />
      <path
        d="M71.9174 18.2585C68.021 18.2585 64.881 15.202 64.881 10.9724C64.881 6.74272 67.9376 3.68627 72.0008 3.68627C75.0173 3.68627 77.6973 5.65289 78.7007 8.50249L82.0939 7.20582C80.5872 2.89282 76.6074 0.00309034 71.8742 0.00309034C65.9678 0.00309034 61.1111 4.52602 61.1111 10.9754C61.1111 17.0482 65.5911 21.9478 72.0841 21.9478C76.8575 21.9478 80.5439 19.3081 82.2205 14.3252L78.8273 13.1952C77.864 16.1683 75.1408 18.2615 71.9174 18.2615V18.2585Z"
        fill="currentColor"
      />
      <path
        d="M96 0.370483V12.1857C96 17.3631 91.7887 21.5742 86.6109 21.5742H84.4558V17.7984H86.6109C88.5437 17.7984 90.5166 16.8167 91.7269 15.3286C91.2545 15.4366 90.7513 15.4984 90.214 15.4984C86.719 15.4984 84.4589 13.115 84.4589 9.45648V0.370483H88.1577V9.6201C88.1577 10.855 88.9389 11.7998 90.214 11.7998C91.2854 11.7998 92.0418 11.0897 92.2271 10.0987V0.370483H96Z"
        fill="currentColor"
      />
      <path
        d="M0 6.04807C0 8.14746 1.15164 9.58615 2.63366 10.2468C1.05901 11.0526 0.0432297 12.8896 0.00309053 14.9797V21.5742H3.74515V14.9797C3.74515 13.3743 4.7733 11.8924 6.5023 11.8924H8.55857V8.1876H5.8848C4.56642 8.1876 3.74515 7.32314 3.74515 6.13143C3.74515 4.93972 4.56951 4.07527 5.8848 4.07527H8.55857V0.370483H5.76129C2.71701 0.370483 0 2.79712 0 6.04807Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37086 30.9227H3.34378L3.67106 31.8674H4.7208L2.93932 26.903H1.7815L0 31.8674H1.04358L1.37086 30.9227ZM2.35886 28.0514L3.07517 30.1261H1.64256L2.35886 28.0514Z"
        fill="currentColor"
      />
      <path
        d="M15.5147 28.07C15.734 27.6841 16.0365 27.3846 16.4163 27.1685C16.7961 26.9524 17.2252 26.8443 17.6976 26.8443C18.2503 26.8443 18.7381 26.9863 19.1518 27.2704C19.5687 27.5544 19.8589 27.9465 20.0256 28.4497H18.8832C18.769 28.212 18.6084 28.036 18.4047 27.9156C18.1978 27.7983 17.9601 27.7396 17.6914 27.7396C17.4012 27.7396 17.145 27.8076 16.9196 27.9434C16.6942 28.0792 16.5182 28.2676 16.3947 28.5145C16.2712 28.7615 16.2063 29.0487 16.2063 29.3821C16.2063 29.7155 16.2681 29.9965 16.3947 30.2435C16.5213 30.4904 16.6942 30.6849 16.9196 30.8177C17.145 30.9535 17.4012 31.0215 17.6914 31.0215C17.9601 31.0215 18.1978 30.9597 18.4047 30.8393C18.6115 30.7189 18.769 30.5398 18.8832 30.3021H20.0256C19.8589 30.8084 19.5687 31.2036 19.1549 31.4846C18.7412 31.7655 18.2534 31.9075 17.6945 31.9075C17.2222 31.9075 16.793 31.7995 16.4132 31.5833C16.0334 31.3672 15.7309 31.0678 15.5117 30.6849C15.2925 30.3021 15.1813 29.8668 15.1813 29.379C15.1813 28.8912 15.2925 28.4559 15.5117 28.07H15.5147Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.86 30.9227H33.8329L34.1602 31.8674H35.21L33.4285 26.903H32.2707L30.4892 31.8674H31.5328L31.86 30.9227ZM32.845 28.0514L33.5612 30.1261H32.1287L32.845 28.0514Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.0266 27.2148C49.4218 27.4185 49.7275 27.7088 49.9436 28.0854C50.1597 28.4621 50.2678 28.8974 50.2678 29.3944C50.2678 29.8915 50.1597 30.3268 49.9436 30.6973C49.7275 31.0678 49.4218 31.358 49.0266 31.5586C48.6314 31.7593 48.1745 31.8643 47.6527 31.8643H45.9206V26.9091H47.6527C48.1745 26.9091 48.6314 27.011 49.0266 27.2148ZM48.8228 30.5954C49.1069 30.3114 49.2489 29.91 49.2489 29.3944C49.2489 28.8788 49.1069 28.4744 48.8228 28.1842C48.5388 27.894 48.1374 27.7489 47.6156 27.7489H46.9116V31.0215H47.6156C48.1374 31.0215 48.5388 30.8794 48.8228 30.5954Z"
        fill="currentColor"
      />
      <path
        d="M62.8926 28.953V27.7057H64.776V26.8968H61.8984V31.8612H64.776V31.0523H62.8926V29.7402H64.563V28.953H62.8926Z"
        fill="currentColor"
      />
      <path
        d="M81.2819 26.9091V31.8643H80.2877V28.6411L78.96 31.8643H78.2067L76.8729 28.6411V31.8643H75.8787V26.9091H77.0087L78.5865 30.5954L80.1642 26.9091H81.2819Z"
        fill="currentColor"
      />
      <path
        d="M94.3235 30.1416L96 26.9091H94.8916L93.8326 29.1752L92.7674 26.9091H91.6466L93.3293 30.1416V31.8674H94.3235V30.1416Z"
        fill="currentColor"
      />
    </>
  ),
});

export default Company;
