import { useMediaQuery, useTheme } from "@chakra-ui/react";

export default function useDevice() {
  const { __breakpoints } = useTheme();
  const [isMobileDevice] = useMediaQuery(`(max-device-width: ${__breakpoints?.asObject.sm})`);

  return {
    isMobileDevice,
  };
}
