import { useRef } from "react";

import { Box, Button, FormControl, FormLabel, Flex, FormErrorMessage, Input } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { NavLink, useLocation } from "react-router-dom";
import * as Yup from "yup";

import { useUserSignUp } from "~/api/users/createAccount";
import { useNavigate, useTitle } from "~/hooks";
import useNamespace from "~/hooks/useNamespace";
import { Password, Alert, ImperativeAlertProps } from "~/theme/components";
import { Company } from "~/theme/icons";
import { yupTest } from "~/utils/passVerify";

import { useLoggedOut } from "../Layout";

export interface SignUpProps {
  name: string;
  email: string;
  confirm_email: string;
  password: string;
}

const schema = Yup.object({
  name: Yup.string().required("signUp@validation-name-required"),
  email: Yup.string().required("signUp@validation-email-required").email("signUp@validation-email-invalid"),
  confirm_email: Yup.string().oneOf([Yup.ref("email"), null], "signUp@validation-confirm-email"),
  password: Yup.string()
    .required("signUp@validation-password-required")
    .test(...yupTest("signUp")),
}).required();

export default function SignUp() {
  const { auth, ...product } = useLoggedOut();
  const [mutate, { loading, error }] = useUserSignUp();
  const { search } = useLocation();
  const { translate } = useNamespace("signUp");
  const alertRef = useRef<ImperativeAlertProps>(null);
  const navigate = useNavigate();
  const { formState, register, handleSubmit } = useForm<SignUpProps>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
  });

  const onSubmit: SubmitHandler<SignUpProps> = async (data) => {
    const source = product.name === "Fluency Academy" ? "ACCOUNT" : product.name.toUpperCase();
    const user = await mutate({ ...data, source });

    if (user) {
      const { email, password } = data;

      auth.setAuthenticate(email, password);
      navigate(`/signup/validate-email/${product.id ? `?product=${product.name.toLowerCase()}` : ""}`, {
        state: { email },
      });
    } else alertRef.current?.turnOnAlert();
  };

  useTitle(`${translate("signUp@title")} | ${product.name}`);

  return (
    <>
      <Box textStyle="h5" textAlign="center" color="gray.900">
        {translate("signUp@signup-title")}
      </Box>
      <Alert status="error" ref={alertRef}>
        {(error as any as Error)?.message}
      </Alert>
      <FormControl
        display="flex"
        flexDirection="column"
        gridRowGap={["4", "8"]}
        width="100%"
        as="form"
        isInvalid={formState.isDirty}
      >
        <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="name" textStyle="label.primary" color="black">
              {translate("signUp@form-name-label")}
            </FormLabel>
            <Input
              type="text"
              id="name"
              data-testid="name"
              isInvalid={!!formState.errors.name}
              required
              {...register("name")}
            />
            {formState.errors.name && (
              <FormErrorMessage>
                {translate(formState.errors.name.message as "signUp@validation-name-required")}
              </FormErrorMessage>
            )}
          </Flex>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="email" textStyle="label.primary" color="black">
              {translate("signUp@form-email-label")}
            </FormLabel>
            <Input
              type="email"
              id="email"
              data-testid="email"
              isInvalid={!!formState.errors.email}
              required
              {...register("email")}
            />
            {formState.errors.email && (
              <FormErrorMessage>
                {translate(formState.errors.email.message as "signUp@validation-email-invalid")}
              </FormErrorMessage>
            )}
          </Flex>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="confirm_email" textStyle="label.primary" color="black">
              {translate("signUp@form-confirm-email-label")}
            </FormLabel>
            <Input
              type="email"
              id="confirm_email"
              data-testid="confirm_email"
              isInvalid={!!formState.errors.confirm_email}
              required
              {...register("confirm_email")}
            />
            {formState.errors.confirm_email && (
              <FormErrorMessage>
                {translate(formState.errors.confirm_email.message as "signUp@validation-email-required")}
              </FormErrorMessage>
            )}
          </Flex>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="password" textStyle="label.primary" color="black">
              {translate("signUp@form-password-label")}
            </FormLabel>
            <Password
              id="password"
              data-testid="password"
              isInvalid={!!formState.errors.password}
              required
              {...register("password")}
              hint
            />
          </Flex>
        </Flex>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!formState.isValid}
          isLoading={loading}
          data-testid="submit"
          data-type="primary"
          isFullWidth
        >
          {translate("signUp@form-signup-button")}
        </Button>
      </FormControl>
      <Flex justifyContent="center" width="inherit" gridRowGap="6" my={["4", "auto"]}>
        <Flex direction="row" alignItems="center" gridColumnGap="1">
          <Flex flexWrap="wrap" gap="1" justify="center" align="center" textStyle="label.primary" color="gray.600">
            <Box>{translate("signUp@form-signin-label")}</Box>
            <Button display="inline-flex" as={NavLink} to={`/signin/${search}`} variant="terciary">
              {translate("signUp@form-signin-link")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex justifyContent="center" width="inherit" gridRowGap="6" mt="4">
        <Flex direction="column" alignItems="center" gridColumnGap="1" fontSize=".875rem">
          <Box as="p" color="gray.600">
            {translate("signUp@agreements-phrase")}
          </Box>
          <Flex as="p" color="gray.600" align="center" gap="1" flexWrap="wrap" justify="center">
            {translate("signUp@agreements-phrase-or")}
            <Button as={NavLink} to={`/signin/${search}`} variant="terciary" fontSize=".875rem">
              {translate("signUp@agreements-terms")}
            </Button>
            {translate("signUp@agreements-phrase-and")}
            <Button as={NavLink} to={`/signin/${search}`} variant="terciary" fontSize=".875rem">
              {translate("signUp@agreements-policy")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Company alignSelf="center" width="24" height="8" color="gray.300" />
    </>
  );
}
