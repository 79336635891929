import { AppComponent, Heading } from "@fluencyacademy/core-components-web";
import { twMerge } from "tailwind-merge";

import { HomeProduct } from "../types/product";
import MoreDetailsLabel from "./more-details-label";
import ProductBgIcon from "./product-bg-icon";
import ProductIcon from "./product-icon";
import ProductRedirectButton from "./product-redirect-button";

type ProductTitleProps = {
  product: HomeProduct;
  title: string;
  bgColor: string;
};

const ProductTitle: AppComponent<ProductTitleProps> = ({ product, title, bgColor }) => (
  <div
    className={twMerge(
      "flex h-full w-full min-h-[168px] items-center md:justify-center md:p-[0px] pl-[20px] pr-[16px] cursor-pointer animate-width group",
      bgColor
    )}
  >
    <div className="flex flex-col min-w-[112px] md:min-w-[168px] justify-center md:items-center items-start">
      <ProductIcon product={product} />
      <Heading
        variant="h5"
        className="!font-gt md:mt-[12px] mt-[8px] text-basic-white-50 text-[1.5rem] leading-[2rem] mb-[2px] md:mb-0"
      >
        {title}
      </Heading>
      <MoreDetailsLabel />
    </div>
    <div className="md:hidden flex w-full h-full items-center justify-end md:justify-center relative">
      <ProductBgIcon product={product} />
      <ProductRedirectButton
        product={product}
        className="!bg-basic-white-50 !text-brand-primary-700 min-w-[174px]"
        iconClassName="text-brand-primary-700 ml-[8px]"
      />
    </div>
  </div>
);

export default ProductTitle;
