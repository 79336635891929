import { ReactNode } from "react";

import { AppComponent } from "@fluencyacademy/core-components-web";

import { CoursesBgIcon } from "../icons/courses-bg-icon";
import { MemorizationBgIcon } from "../icons/memorization-bg-icon";
import { TalksBgIcon } from "../icons/talks-bg-icon";
import { HomeProduct } from "../types/product";

type ProductIconProps = {
  product: HomeProduct;
};

const ProductBgIcon: AppComponent<ProductIconProps> = ({ product }) => {
  let icon: ReactNode | undefined;

  switch (product) {
    case HomeProduct.COURSES:
      icon = <CoursesBgIcon className="w-full h-full py-[12px] absolute left-[35px]" />;
      break;
    case HomeProduct.MEMORIZATION:
      icon = <MemorizationBgIcon className="w-full h-full py-[12px] absolute left-[35px]" />;
      break;
    case HomeProduct.TALKS:
      icon = <TalksBgIcon className="w-full h-full py-[12px] absolute left-[35px]" />;
      break;
  }

  return <div className="min-h-[140px] absolute flex z-10 w-full h-full items-center justify-center">{icon}</div>;
};

export default ProductBgIcon;
