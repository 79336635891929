const red = {
  50: "#ffe7e7",
  100: "#f5c0c0",
  200: "#e89898",
  300: "#dd6f70",
  400: "#D14545",
  500: "#b82e2e",
  600: "#902222",
  700: "#671718",
  800: "#400c0d",
  900: "#1d0101",
};

export default red;
