import { Center, Spinner } from "@chakra-ui/react";

function LoadingPage() {
  return (
    <Center width="100vw" height="100vh">
      <Spinner boxSize="12" thickness="0.25rem" color="purple.300" margin="auto" />
    </Center>
  );
}

export default LoadingPage;
