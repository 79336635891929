import { ReactNode } from "react";

import { AppComponent, Heading, Paragraph } from "@fluencyacademy/core-components-web";
import { twMerge } from "tailwind-merge";

import { HomeProduct } from "../types/product";
import ProductRedirectButton from "./product-redirect-button";

type ProductContentProps = {
  title: string;
  description: string | ReactNode;
  backgroundColor: string;
  extraHeader?: ReactNode;
  extraDescription?: ReactNode;
  mainImgSrc: string;
  mainImgAlt?: string;
  product: HomeProduct;
};
const ProductContent: AppComponent<ProductContentProps> = ({
  product,
  title,
  description,
  backgroundColor,
  extraHeader,
  extraDescription,
  mainImgSrc,
  mainImgAlt,
}) => (
  <div
    className={twMerge(
      "flex flex-grow md:flex-row flex-col-reverse w-full h-full justify-center items-center md:pl-[60px] md:pr-[40px] px-[20px] py-[22px] animate-width gap-6",
      backgroundColor
    )}
  >
    <div className="flex flex-col gap-[8px] justify-center max-w-[412px]">
      {extraHeader}
      <Heading
        variant="h3"
        className="text-basic-white-50 text-[2rem] md:text-[3rem] leading-[2.5rem] md:leading-[3.5rem] md:mb-[16px] mt-[6px] md:mt-0 text-start"
      >
        {title}
      </Heading>
      <div className="md:mb-[32px] mb-[16px] flex flex-col flex-start">
        {extraDescription}
        <Paragraph variant="01_500" className="text-basic-white-50 text-start">
          {description}
        </Paragraph>
      </div>
      <ProductRedirectButton
        product={product}
        className="!bg-basic-white-50 text-brand-primary-700 flex justify-center items-center w-full md:max-w-[224px]"
        iconClassName="flex text-brand-primary-700 ml-[10px]"
      />
    </div>
    <div className="flex">
      <img
        src={mainImgSrc}
        alt={mainImgAlt ?? "Product image"}
        className="object-contain md:max-h-[460px] lg:max-h-[600px]"
      />
    </div>
  </div>
);

export default ProductContent;
