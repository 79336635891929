import useFetch from "~/hooks/useFetchApi";
import { fetcher } from "~/services/http";

export type GenderTypes = "MALE" | "FEMALE" | "CUSTOM" | null;

export interface AddressProps {
  postal_code: string;
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  complement: string;
}

export interface ResponseProps {
  id: string;
  name: string;
  email: string;
  avatar: any | null;
  birthdate: string | null;
  gender: GenderTypes | null;
  native_language: string;
  phone_number: string;
  instagram: string | null;
  facebook: string | null;
  twitter: string | null;
  address: AddressProps;
}

const getUser = () => fetcher<ResponseProps, unknown>({ url: "/users/me/" });
const useUser = () => useFetch<ResponseProps>("/users/me/");

export { getUser, useUser };
