import { createEndpoint } from "~/api";

export type AuthStateTypes = "AUTHENTICATED" | "NEW_PASSWORD_REQUIRED";

export interface RequestProps {
  previous_password: string;
  new_password: string;
}

const resetPassword = createEndpoint<"POST", RequestProps, any>({ path: "/auth/password/reset/" });

export default resetPassword;
