import useSWR from "swr";

import { fetcher } from "~/services/http";

export interface ResponseProps {
  id: string;
  name: string;
  logo: string;
  background_image: string;
  callback_url: string;
  brand_color: string;
}

const getProduct = (slug: string) => fetcher<ResponseProps, unknown>({ url: `/products/${slug}/`, method: "GET" });
const useFetchProduct = (slug: string) => useSWR<ResponseProps>(slug ? `/products/${slug}/` : null, getProduct(slug));

export { getProduct, useFetchProduct };
