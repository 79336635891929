import { Box } from "@chakra-ui/react";
import { matchRoutes, Navigate, Outlet, RouteObject, useLocation } from "react-router-dom";

import { goto, useProduct, useQueryParam } from "~/hooks";
import useAuth from "~/hooks/useAuth";
import { LoadingPage } from "~/theme/components";

export interface ExceptRoutesProps {
  exceptRoutes?: RouteObject[];
}

function useSession() {
  const auth = useAuth();
  const location = useLocation();
  const params = useQueryParam("product");
  const product = useProduct();

  // HOC to add `exceptRoutes` as props
  function withExceptRoutes<T>(Component: React.ComponentType<T & ExceptRoutesProps>) {
    return ({ exceptRoutes = [], ...props }: React.PropsWithChildren<T & ExceptRoutesProps>) => (
      <Component {...(props as T)} exceptRoutes={exceptRoutes} />
    );
  }

  // HOC to protect routes for authenticated users
  function withSession<T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    return withExceptRoutes<T>(({ exceptRoutes, ...props }) => {
      const isRouteExcluded = matchRoutes(exceptRoutes!, location) !== null;

      // Redirect to the sign-in page if the user is not authenticated
      if (!auth.isUserIn && !isRouteExcluded) {
        const signinPath = params.product ? `/signin/?product=${params.product}` : "/signin/";

        return <Navigate to={signinPath} />;
      }

      // Redirect the user to the product if it exists
      if (product.callback_url && !isRouteExcluded) {
        if (product.callback_url) {
          goto(decodeURIComponent(product.callback_url));
        } else {
          const redirectUrl = new URLSearchParams(location.search).get("url") as string;

          goto(decodeURIComponent(redirectUrl));
        }

        return <LoadingPage />;
      }

      return <Component {...(props as T)} />;
    });
  }

  // HOC to protect routes for unauthenticated users
  function withNoSession<T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) {
    return withExceptRoutes<T>(({ exceptRoutes, ...props }) => {
      const isRouteExcluded = matchRoutes(exceptRoutes!, location) !== null;
      const shouldRedirect = auth.isUserIn && !isRouteExcluded && !auth.validation;

      if (shouldRedirect) {
        // Redirect the authenticated user to the callback URL or home page
        if (product.callback_url && !isRouteExcluded) {
          if (product.callback_url) {
            goto(decodeURIComponent(product.callback_url));
          } else {
            const redirectUrl = new URLSearchParams(location.search).get("url") as string;

            goto(decodeURIComponent(redirectUrl));
          }

          return <LoadingPage />;
        }
      }

      return <Component {...(props as T)} />;
    });
  }

  // Component for protected routes
  function SignedIn(props: React.PropsWithChildren<unknown>) {
    const ProtectedComponent = withSession(Box);

    return (
      <ProtectedComponent {...props}>
        <Outlet />
      </ProtectedComponent>
    );
  }

  // Component for unprotected routes
  function SignedOut() {
    const UnprotectedComponent = withNoSession(Box);

    return <UnprotectedComponent />;
  }

  return {
    withNoSession,
    withSession,
    SignedIn,
    SignedOut,
  };
}

export default useSession;
