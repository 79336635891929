import { HomeProduct } from "../types/product";

const notNullOrUndefined = <T>(value: T): value is NonNullable<T> => value != null;

const platformToProductMap: Record<string, HomeProduct | null> = {
  FLUENCY_TALKS: HomeProduct.TALKS,
  YOWZA: HomeProduct.TALKS,
  LMS: HomeProduct.COURSES,
  ACADEMY: HomeProduct.COURSES,
};

const mapProductsFromPlatforms = (platforms: string[]): HomeProduct[] => {
  const products = platforms.map((platform) => platformToProductMap[platform] ?? null).filter(notNullOrUndefined);

  return products;
};

export { mapProductsFromPlatforms };
