import { createIcon } from "@chakra-ui/icons";

const Account = createIcon({
  displayName: "Account",
  viewBox: "0 0 106 24",
  path: (
    <>
      <path
        d="M0.0857544 23.6287H3.7482L4.79461 19.0886H11.2925L12.3389 23.6287H16.2714L10.4655 0H5.84103L0.0857544 23.6287ZM5.38533 16.3544L6.83681 10.0422C7.27563 7.88186 7.57943 6.19409 7.86634 3.93249H8.18702C8.50769 6.19409 8.82837 7.88186 9.26719 10.0422L10.7018 16.3544H5.38533Z"
        fill="currentColor"
      />
      <path
        d="M23.5119 24C27.2925 24 29.7229 22.0253 29.8579 17.384L26.1279 16.9114V17.4177C26.0773 20.3038 25.1659 21.4177 23.5963 21.4177C21.7735 21.4177 20.9128 19.8987 20.9128 16.7426V12.6413C20.9128 9.56962 21.7904 8.10126 23.5794 8.10126C25.1322 8.10126 26.0098 9.31646 26.0604 12.827L29.706 12.3713C29.6047 7.59494 27.3431 5.53586 23.5457 5.53586C19.3094 5.53586 17.1659 8.13502 17.1659 13.1814V16.2025C17.1659 21.3333 19.2756 24 23.5119 24Z"
        fill="currentColor"
      />
      <path
        d="M38.2967 24C42.0773 24 44.5077 22.0253 44.6427 17.384L40.9128 16.9114V17.4177C40.8621 20.3038 39.9507 21.4177 38.3811 21.4177C36.5583 21.4177 35.6976 19.8987 35.6976 16.7426V12.6413C35.6976 9.56962 36.5752 8.10126 38.3642 8.10126C39.917 8.10126 40.7946 9.31646 40.8452 12.827L44.4908 12.3713C44.3895 7.59494 42.1279 5.53586 38.3305 5.53586C34.0942 5.53586 31.9507 8.13502 31.9507 13.1814V16.2025C31.9507 21.3333 34.0604 24 38.2967 24Z"
        fill="currentColor"
      />
      <path
        d="M80.2883 5.90717H76.6258V15.7468C76.6258 19.0211 75.3938 21.2489 73.5541 21.2489C72.3727 21.2489 71.7313 20.4726 71.7313 19.0042V5.90717H68.0858V19.7637C68.0858 22.481 69.4022 23.8987 72.0014 23.8987C74.3305 23.8987 75.9845 22.5992 77.149 19.6118L77.4697 19.7468C76.879 21.6034 76.6596 22.616 76.6427 23.6287H80.2883V5.90717Z"
        fill="currentColor"
      />
      <path
        d="M83.36 23.6287H87.0225V13.789C87.0225 10.5148 88.2714 8.28692 90.1111 8.28692C91.2925 8.28692 91.917 9.06329 91.917 10.5316V23.6287H95.5794V9.77215C95.5794 7.05485 94.2461 5.63713 91.6638 5.63713C89.3347 5.63713 87.6638 6.93671 86.5161 9.92405L86.1786 9.78903C86.7862 7.93249 86.9887 6.91983 87.0056 5.90717H83.36V23.6287Z"
        fill="currentColor"
      />
      <path
        d="M98.7592 18.8587C98.7592 21.7622 100.695 23.6287 103.425 23.6287H105.914V20.5178H103.771C102.492 20.5178 101.87 19.9302 101.87 18.6513V8.97296H105.396V5.86208H102.561C102.077 5.86208 101.87 5.62012 101.87 5.1362V1.19575L98.7592 2.12901V18.8587Z"
        fill="currentColor"
      />
      <path
        d="M62.8689 3.5C62.1776 4.67522 61.3481 5.71219 60.4148 6.50719C59.1359 5.85045 57.6496 5.47023 56.025 5.47023C50.7019 5.47023 46.6923 9.44525 46.6923 14.7683C46.6923 20.0223 50.7019 23.9627 56.025 23.9627C61.3481 23.9627 65.3576 20.0223 65.3576 14.7683C65.3576 12.2796 64.4589 10.0674 62.9726 8.40829C63.9405 7.47502 64.8046 6.36893 65.565 5.09001L62.8689 3.5ZM62.2468 14.7683C62.2468 18.294 59.5852 20.9555 56.025 20.9555C52.4993 20.9555 49.8723 18.294 49.8723 14.7683C49.8723 12.8672 50.5982 11.2426 51.7734 10.102C52.7758 10.9661 54.193 11.45 55.7485 11.45C57.5459 11.45 59.1013 11.0353 60.5185 10.2748C61.59 11.4155 62.2468 12.9709 62.2468 14.7683Z"
        fill="#F7BD2F"
      />
    </>
  ),
});

export default Account;
