import { AppComponent } from "@fluencyacademy/core-components-web";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

type AppearDivProps = {
  delay?: number;
};
const AppearDiv: AppComponent<AppearDivProps> = ({ children, className, delay }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.98 }}
    whileInView={{
      opacity: 100,

      scale: 1,
      transition: {
        type: "tween",
        delay: delay,
        duration: 0.2,
      },
    }}
    className={twMerge(className)}
  >
    {children}
  </motion.div>
);

export default AppearDiv;
