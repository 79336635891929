import { createIcon } from "@chakra-ui/icons";

const Facebook = createIcon({
  displayName: "Facebook",
  viewBox: "0 0 14 14",
  path: (
    <path
      d="M12.2383 0.333252H1.76168C1.38286 0.333252 1.01956 0.483737 0.751693 0.751601C0.483828 1.01947 0.333344 1.38277 0.333344 1.76158L0.333344 12.2382C0.333344 12.6171 0.483828 12.9804 0.751693 13.2482C1.01956 13.5161 1.38286 13.6666 1.76168 13.6666H5.84501V9.13325H3.97168V6.99992H5.84668V5.37492C5.80621 4.99511 5.8498 4.61104 5.97434 4.24995C6.09888 3.88887 6.30132 3.55959 6.56732 3.28548C6.83332 3.01137 7.15637 2.79913 7.51355 2.6638C7.87074 2.52848 8.25332 2.47338 8.63418 2.50242C9.18779 2.51014 9.74009 2.55832 10.2867 2.64658V4.45825H9.35668C9.19768 4.437 9.03594 4.45188 8.8835 4.50179C8.73105 4.5517 8.59182 4.63534 8.47618 4.74651C8.36053 4.85767 8.27145 4.99348 8.21555 5.14383C8.15966 5.29418 8.13839 5.45521 8.15334 5.61492V6.99992H10.2L9.87251 9.13325H8.15334V13.6666H12.2367C12.4244 13.6668 12.6103 13.63 12.7838 13.5583C12.9573 13.4867 13.1149 13.3815 13.2477 13.2488C13.3805 13.1162 13.4859 12.9586 13.5578 12.7852C13.6297 12.6118 13.6667 12.426 13.6667 12.2382V1.76158C13.6667 1.38277 13.5162 1.01947 13.2483 0.751601C12.9805 0.483737 12.6172 0.333252 12.2383 0.333252Z"
      fill="currentColor"
    />
  ),
});

export default Facebook;
