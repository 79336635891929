import { useState } from "react";

import { Box, Button, Flex, Radio, RadioGroup } from "@chakra-ui/react";

import { useLoggedOut } from "~/auth/Layout";
import { Alert } from "~/theme/components";
import { ArrowLeft } from "~/theme/icons";

export default function EmailList() {
  const { goBack, auth } = useLoggedOut();
  const [value, setValue] = useState<string>();

  return (
    <>
      {auth.error && <Alert>{auth.error?.message}</Alert>}
      <Flex width="100%" direction="column" alignItems="flex-start" gridRowGap="2">
        <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
          Voltar
        </Button>
        <Box textStyle="h5" color="gray.900">
          Agora, escolha seu e-mail
        </Box>
      </Flex>
      <RadioGroup width="100%" onChange={setValue} value={value}>
        <Flex direction="column" gridRowGap="1">
          <Radio value="pedro.henrique@fluencyacademy.io">pedro.henrique@fluencyacademy.io</Radio>
          <Radio value="judson.cruz@fluencyacademy.io">judson.cruz@fluencyacademy.io</Radio>
        </Flex>
      </RadioGroup>
      <Button isFullWidth>Continuar</Button>
    </>
  );
}
