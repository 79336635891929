import { Flex, Button, FormLabel, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Yup from "yup";

import useNamespace from "~/hooks/useNamespace";
import { Password } from "~/theme/components";
import { yupTest } from "~/utils/passVerify";

type CreatePasswordProps = {
  password: string;
  confirm_password: string;
};

type Props = {
  loading: boolean;
  createPassword: (password: string) => void;
};

const schema = Yup.object({
  password: Yup.string()
    .required("forgotPassword@validation-password-required")
    .test(...yupTest("forgotPassword")),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "forgotPassword@validation-confirm-password-invalid"
  ),
}).required();

function CreatePassword({ createPassword, loading }: Props) {
  const { translate } = useNamespace("forgotPassword");

  const { formState, register, handleSubmit } = useForm<CreatePasswordProps>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
  });

  const onSubmit: SubmitHandler<CreatePasswordProps> = async ({ password }) => {
    createPassword(password);
  };

  return (
    <>
      <FormControl display="flex" flexDirection="column" width="100%" gridRowGap={["4", "8"]} as="form">
        <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="password" textStyle="label.primary" color="black">
              {translate("forgotPassword@form-password-label")}
            </FormLabel>
            <Password
              id="password"
              data-testid="password"
              isInvalid={!!formState.errors.password}
              {...register("password")}
              required
              hint
            />
          </Flex>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="confirm_password" textStyle="label.primary" color="black">
              {translate("forgotPassword@form-confirm-password-label")}
            </FormLabel>
            <Password
              id="confirm_password"
              data-testid="confirm_password"
              isInvalid={!!formState.errors.confirm_password}
              {...register("confirm_password")}
              required
            />
            {formState.errors.confirm_password && (
              <FormErrorMessage>
                {translate(formState.errors.confirm_password.message as "forgotPassword@validation-password-required")}
              </FormErrorMessage>
            )}
          </Flex>
        </Flex>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          data-testid="submit"
          data-type="primary"
          isDisabled={!formState.isValid}
          isLoading={loading}
          borderRadius={6}
          isFullWidth
        >
          {translate("forgotPassword@create-password-signin")}
        </Button>
      </FormControl>
    </>
  );
}

export default CreatePassword;
