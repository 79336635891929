import { useState } from "react";

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Link,
  PinInput,
  PinInputField,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useElapsedTime } from "use-elapsed-time";
import * as Yup from "yup";

import { useLoggedOut } from "~/auth/Layout";
import { useNavigate, useTitle } from "~/hooks";
import { Phone, PhoneInputProps } from "~/theme/components";
import { ArrowLeft } from "~/theme/icons";

import { ForgotConfig } from "..";

import * as Obfuscate from "~/utils/obfuscate";

export interface ForgotEmailProps {
  phone: PhoneInputProps;
}

const schema = Yup.object({
  phone: Yup.string()
    .required("Preencha o número de telefone")
    .test("phone-validation", "Preencha o número por completo", (password) => !/_/.test(password as string)),
}).required();

function ForgotEmail() {
  const { goBack, ...product } = useLoggedOut();
  const navigate = useNavigate();
  const { onOpen, isOpen } = useDisclosure();
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying: isOpen,
    duration: ForgotConfig.duration,
    updateInterval: 1,
  });
  const [pin, setPin] = useState<string>();
  const seconds = Math.round(ForgotConfig.duration - elapsedTime);

  const { formState, getValues, handleSubmit, control } = useForm<ForgotEmailProps>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
  });

  const onSubmit: SubmitHandler<ForgotEmailProps> = (_data) => {
    if (isOpen) {
      // request for listing new items
      const _apiKey = "123456789";

      navigate("list", { state: { apiKey: _apiKey } });
    } else {
      // request password resetting authorization

      onOpen();
    }
  };

  const handleResend: React.MouseEventHandler<HTMLButtonElement> = () => {
    reset();
  };

  useTitle(`Recuperar email | ${product.name}`);

  return (
    <>
      {/* {auth.error && <Alert>{auth.error?.message}</Alert>} */}
      <Flex width="100%" direction="column" alignItems="flex-start" gridRowGap="2">
        <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
          Voltar
        </Button>
        <Box textStyle="h5" color="gray.900">
          Esqueci meu email
        </Box>
        <Box textStyle="body2" color="gray.700">
          {isOpen
            ? `Insira o código de 6 digitos que enviamos para seu número de telefone ${Obfuscate.phone(
                `${getValues("phone")}`
              )}`
            : "Insira seu número de telefone abaixo e nós enviaremos um código para recuperar seu e-mail"}
        </Box>
      </Flex>
      <FormControl as="form" isInvalid={formState.isDirty}>
        {isOpen ? (
          <Flex justifyContent="space-between" width="100%">
            <PinInput placeholder="" onChange={setPin} value={pin} otp mask>
              <PinInputField required />
              <PinInputField required />
              <PinInputField required />
              <PinInputField required />
              <PinInputField required />
              <PinInputField required />
            </PinInput>
          </Flex>
        ) : (
          <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="3.5">
            <Flex direction="column" width="inherit">
              <FormLabel htmlFor="phone" textStyle="label.primary" color="black">
                Número de telefone
              </FormLabel>
              <Controller
                control={control}
                name="phone"
                rules={{ required: true }}
                render={({ formState, field }) => (
                  <>
                    <Phone isInvalid={!!formState.errors.phone} {...field} />
                    {formState.errors.phone &&
                      Object.values(formState.errors.phone).map(({ message }) => (
                        <FormErrorMessage key={message}>{message}</FormErrorMessage>
                      ))}
                  </>
                )}
              />
            </Flex>
            <Flex direction="row" alignItems="center" gridColumnGap="1">
              <Box textStyle="body2" color="gray.600">
                Esqueceu o número de telefone? Acione o
              </Box>
              <Link as={NavLink} to="/" color="purple.300">
                Suporte
              </Link>
            </Flex>
          </Flex>
        )}
      </FormControl>
      <Flex direction="column" width="100%" gridRowGap="6" my={["4", "auto"]}>
        <Button
          onClick={handleSubmit(onSubmit)}
          data-testid="submit"
          isDisabled={isOpen ? (pin?.length ?? 0) < 6 : !formState.isValid}
          isFullWidth
        >
          {isOpen ? "Continuar" : "Recuperar meu e-mail"}
        </Button>
        {isOpen && (
          <Button
            variant="secondary"
            onClick={handleResend}
            isDisabled={!(elapsedTime === ForgotConfig.duration || seconds === 0)}
            isFullWidth
          >
            Reenviar código {seconds ? `(${String(seconds).padStart(2, "0")}s)` : ""}
          </Button>
        )}
      </Flex>
    </>
  );
}

export { default as EmailList } from "./List";
export * from "./List";

export default ForgotEmail;
