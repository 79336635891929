import { useTranslation } from "react-i18next";

import { getCurrentLocale, LanguageProps, LANGUAGES, setCurrentLocale } from "~/utils/locale";

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (locale: string) => {
    i18n.changeLanguage(locale);

    setCurrentLocale(locale);
  };

  const getCurrentLanguage = (): LanguageProps => {
    const currentLocale = getCurrentLocale();
    const language = LANGUAGES.find(({ locale }) => locale === currentLocale);

    return language || LANGUAGES[0];
  };

  return {
    i18n,
    changeLanguage,
    getCurrentLanguage,
  };
};
