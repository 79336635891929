import { useEffect } from "react";

import { identifyUser } from "~/services/amplitude";

type UserProps = {
  id: string | null | undefined;
};

function useAmplitude(data: UserProps) {
  useEffect(() => {
    if (data.id) {
      identifyUser(data?.id);
    }
  }, [data]);
}

export default useAmplitude;
