import { AxiosRequestConfig } from "axios";

import useMutate from "~/hooks/useMutateApi";

import { ResponseProps } from "./retrieve";

export interface RequestProps {
  name: string;
  email: string;
  avatar: any | null;
  birthdate: string | null;
  gender: string | null;
  native_language: string | null;
  phone_number: string | null;
  instagram: string | null;
  facebook: string | null;
  twitter: string | null;
}

const useUpdateUser = (params?: AxiosRequestConfig) =>
  useMutate<ResponseProps, Partial<RequestProps> | FormData>("/users/me/", { method: "PATCH", ...params });

export { useUpdateUser };
