import { ReactNode } from "react";

import { AppComponent } from "@fluencyacademy/core-components-web";
import { twMerge } from "tailwind-merge";

import { HomeProduct } from "../types/product";

type CollapseItemProps = {
  title: ReactNode;
  content: ReactNode;
  product: HomeProduct;
  isSelected: boolean;
  onChangeSelected: () => void;
};

const CollapseItem: AppComponent<CollapseItemProps> = ({ title, content, isSelected, onChangeSelected }) => (
  <button
    className={twMerge(
      "flex bg-base-200 rounded-[23px] overflow-clip cursor-auto transition-all duration-300",
      isSelected ? "h-[75%] md:w-[75%] md:h-full" : "h-[12.5%] md:w-[12.5%] md:h-full"
    )}
    onClick={onChangeSelected}
  >
    {isSelected ? content : title}
  </button>
);

export default CollapseItem;
