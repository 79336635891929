import { useRef, useState } from "react";

import {
  PinInput,
  PinInputField,
  Button,
  Flex,
  Grid,
  Box,
  FormControl,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  VStack,
} from "@chakra-ui/react";
import { Navigate, useLocation } from "react-router-dom";
import useSWR from "swr";
import { useElapsedTime } from "use-elapsed-time";

import { useValidateVerificationCode } from "~/api/validation/verifyEmail";
import { useLoggedOut } from "~/auth/Layout";
import { useDevice, useTitle } from "~/hooks";
import useNamespace from "~/hooks/useNamespace";
import http from "~/services/http";
import { Alert, ImperativeAlertProps } from "~/theme/components";
import { ArrowLeft, CircleQuestion } from "~/theme/icons";
import * as Obfuscate from "~/utils/obfuscate";

export interface EmailNavigateProps {
  email: string;
}

export enum ConfirmConfig {
  duration = 30,
}

function ValidateCode() {
  const { state } = useLocation() as { state: EmailNavigateProps };
  const [code, setCode] = useState<string>("");
  const { goBack, auth, ...product } = useLoggedOut();
  const { translate } = useNamespace("validate");
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying: true,
    duration: ConfirmConfig.duration,
    updateInterval: 1,
  });
  const [validateCode, { loading, error }] = useValidateVerificationCode();
  const seconds = Math.round(ConfirmConfig.duration - elapsedTime);
  const alertRef = useRef<ImperativeAlertProps>(null);
  const modal = useDisclosure();
  const { isMobileDevice } = useDevice();
  const { mutate } = useSWR(
    "/users/me/verify-code/email/",
    () => http.post("/users/me/verify-code/email/", { email: state.email }).then((res) => res.data),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );

  const handleSubmit = async () => {
    const response = await validateCode({ code });

    if (response?.data.active) {
      auth.completeAuthentication();
    } else alertRef.current?.turnOnAlert();
  };

  const handleResend = () => {
    mutate({ email: state?.email as string });
    reset();
  };

  useTitle(translate("validate@title", { name: product.name }));
  if (!state) return <Navigate to="/" />;

  return (
    <>
      <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
        <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
          {translate("validate@back")}
        </Button>
        <Box textStyle="h5" color="gray.900">
          {translate("validate@profile-title-email")}
        </Box>
        <Alert status="error" ref={alertRef} autoDisplay={false}>
          {error?.message}
        </Alert>
        <Box textStyle="body2" color="gray.700">
          {translate("validate@profile-subtitle-email", { email: Obfuscate.email(state.email) })}
        </Box>
        <Button onClick={modal.onOpen} fontSize={[".875rem", "1rem"]} variant="terciary" leftIcon={<CircleQuestion />}>
          {translate("validate@profile-modal-button-email")}
        </Button>
      </Flex>
      <FormControl display="flex" flexDirection="column" gridRowGap={["4", "8"]} width="100%" height="100%" as="form">
        <Flex direction="column" width="100%" alignItems="flex-start">
          <Flex justifyContent="space-between" width="100%">
            <PinInput
              placeholder=""
              onChange={(value) => setCode(value.toUpperCase())}
              value={code}
              type="alphanumeric"
              otp
            >
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
            </PinInput>
          </Flex>
        </Flex>
        <Grid gridTemplateColumns="1fr 1fr" width="100%" gap="2" my={["4", "auto"]}>
          <Button
            variant="secondary"
            data-type="secondary"
            onClick={handleResend}
            isDisabled={!(elapsedTime === ConfirmConfig.duration || seconds === 0)}
            isFullWidth
          >
            {translate("validate@form-resend-button")} {seconds ? `(${String(seconds).padStart(2, "0")}s)` : ""}
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            isDisabled={code?.length !== 6}
            isLoading={loading}
            data-testid="submit"
            data-type="primary"
            isFullWidth
          >
            {translate("validate@form-continue-button")}
          </Button>
        </Grid>
      </FormControl>
      <Modal {...modal}>
        <ModalOverlay backgroundColor="rgba(0, 0, 0, .8)" />
        <ModalContent
          width="100%"
          maxWidth="34.5rem"
          alignSelf="center"
          justifySelf="center"
          padding={["8", "12"]}
          margin={["4", "0"]}
          borderRadius="1rem"
        >
          <Box textStyle="h5" fontSize="1.5rem" marginBottom={["8", "12"]}>
            {translate("validate@profile-modal-title-email")}
          </Box>
          {!isMobileDevice && <ModalCloseButton mr="2" mt="2" />}
          <VStack gap="6" align="start">
            <Box textStyle="body2">{translate("validate@profile-modal-text-1-email")}</Box>
            <Box textStyle="body2" marginTop="0!important" fontWeight="500">
              {translate("validate@profile-modal-text-2-email")}
            </Box>
            <Button variant="primary" width="auto" marginTop="0!important" onClick={modal.onClose}>
              {translate("validate@profile-modal-close-button")}
            </Button>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ValidateCode;
