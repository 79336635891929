import { createEndpoint } from "~/api";

export type AuthStateTypes = "AUTHENTICATED" | "NEW_PASSWORD_REQUIRED";

export interface RequestProps {
  email: string;
}

const forgotPassword = createEndpoint<"POST", RequestProps, any>({ path: "/auth/password/forgot/" });

export default forgotPassword;
