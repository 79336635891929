import { useEffect } from "react";

import { init, reactRouterV6Instrumentation } from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

const initSentry = () => {
  if (import.meta.env.PROD)
    init({
      dsn: import.meta.env.VITE_SENTRY_DSN as string,
      environment: import.meta.env.VITE_FEATURED_FLAG as string,
      integrations: [
        new BrowserTracing({
          tracePropagationTargets: [import.meta.env.VITE_API_URL as string],
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      tracesSampleRate: 1,
      normalizeDepth: 10,
    });
};

export default initSentry;
