import { createIcon } from "@chakra-ui/icons";

const ArrowRight = createIcon({
  displayName: "ArrowRight",
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M0 7.99998C0 7.4477 0.447715 6.99998 1 6.99998H12.2L7.3 2.09998C6.9134 1.71338 6.9134 1.08658 7.3 0.699984C7.6866 0.313384 8.3134 0.313384 8.7 0.699984L15.2929 7.29288C15.6834 7.6834 15.6834 8.31657 15.2929 8.70709L8.7 15.3C8.3134 15.6866 7.6866 15.6866 7.3 15.3C6.9134 14.9134 6.9134 14.2866 7.3 13.9L12.2 8.99998H0.999999C0.447714 8.99998 0 8.55227 0 7.99998Z"
      fill="currentColor"
    />
  ),
});

export default ArrowRight;
