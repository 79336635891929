import { Link, Flex, FlexProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { ResponseProps as UserProps } from "~/api/users/retrieve";
import { UserMenu } from "~/features/products-hub/components/user-menu";
import theme from "~/theme";
import SimpleLanguageMenu from "~/theme/components/SimpleLanguageMenu";
import { Company } from "~/theme/icons";

export interface HeaderProps extends FlexProps {
  onOpen(): void;
  user?: UserProps;
}

const Header: React.FC<HeaderProps> = ({ user, ...props }) => (
  <Flex
    bgColor="purple.300"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    color="white"
    position="fixed"
    zIndex={theme.zIndices.docked + 1}
    width="100%"
    height="4.375rem"
    top="0"
    px={["6", "8"]}
    {...props}
  >
    <Link
      as={NavLink}
      to="/"
      sx={{
        "&:link, &:visited, &:hover, &:active": {
          color: "inherit",
        },
      }}
    >
      <Company width="28" height="9" />
    </Link>
    <Flex flexDirection="row" alignItems="center" gap={2}>
      <SimpleLanguageMenu />
      <UserMenu user={user} />
    </Flex>
  </Flex>
);

export default Header;
