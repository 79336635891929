import { Flex } from "@chakra-ui/react";

import { useNamespace } from "~/hooks";

import { Phrase, Step } from "..";

function StepOne() {
  const { translate } = useNamespace("outdoor");

  return (
    <Flex direction="column" gap={["2", "4"]} align="start">
      <Phrase>{translate("outdoor@signUp-step4")}</Phrase>
      <Step>{translate("outdoor@step-4")}</Step>
    </Flex>
  );
}

export default StepOne;
