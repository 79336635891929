export const styles: import("@chakra-ui/theme-tools").Styles = {
  global: (_props) => ({
    "*": {
      "& input": {
        "&:valid": { layerStyle: "valid" },
        "&:invalid": { layerStyle: "invalid" },
      },
    },
    "input::-ms-reveal, input::-ms-clear": {
      display: "none",
    },
  }),
};

export const textStyles = {
  h6: {
    fontWeight: 700,
    fontFamily: "GT Walsheim",
    lineHeight: "shorter",
    fontSize: "md",
  },
  h5: {
    fontWeight: 700,
    fontFamily: "GT Walsheim",
    lineHeight: "7",
    fontSize: "2xl",
  },
  body2: {
    fontWeight: 400,
    fontFamily: "IBM Plex Sans",
    lineHeight: "short",
    fontSize: "sm",
  },
  button: {
    primary: {
      fontWeight: 500,
      fontFamily: "IBM Plex Sans",
      lineHeight: "8",
      fontSize: "md",
    },
  },
  caption2: {
    fontWeight: 500,
    fontFamily: "IBM Plex Sans",
    fontSize: "sm",
    lineHeight: "shorter",
  },
  label: {
    primary: {
      fontWeight: 500,
      fontFamily: "IBM Plex Sans",
      lineHeight: "shorter",
      fontSize: "md",
    },
  },
  subtitle: {
    fontWeight: 500,
    fontFamily: "IBM Plex Sans",
    lineHeight: "none",
    fontSize: "xs",
  },
};

export const layerStyles = {
  invalid: {
    borderColor: "gray.400",
    borderWidth: "0.0625rem",
  },
  valid: {
    borderColor: "gray.600",
    borderWidth: "0.125rem",
  },
  cropOutline: {
    borderStyle: "solid",
    borderWidth: "0.125rem",
    borderColor: "black",
  },
};
