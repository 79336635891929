import { useEffect } from "react";

import { Box, Button, Flex, FormControl, FormLabel, Input, Spinner } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";

import { useDelayedRedirect, useNavigate, useProduct, useQueryParam } from "~/hooks";
import useMutate from "~/hooks/useMutateApi";
import useNamespace from "~/hooks/useNamespace";

const schema = Yup.object({
  email: Yup.string().email().required(),
});

const ENDPOINT = import.meta.env.VITE_API_URL;

function Activate() {
  const { brand_color } = useProduct();
  const navigate = useNavigate();
  const query = useQueryParam("product", "email");
  const { translate } = useNamespace("activate");
  const { register, handleSubmit } = useForm<{ email: string }>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const product_redirect = useDelayedRedirect(`/?product=${query.product}`, 1000);
  const redirect = useDelayedRedirect("/", 1000);
  const [mutate, { loading, error }] = useMutate("auth/activate/", {
    baseURL: ENDPOINT as string,
  });
  const onSubmit: SubmitHandler<{ email: string }> = async ({ email }) => {
    const response = await mutate({ email });

    if (response?.status === 201) {
      const params = new URLSearchParams(`email=${email}&product=${query.product || ""}`);

      navigate(`/activate/code?${params.toString()}`, {
        state: { email },
      });
    }
  };

  useEffect(() => {
    if (query.email) {
      onSubmit({ email: query.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.email]);

  const userNotFound = error?.message?.includes("404");
  const userExists = error?.message?.includes("user already activated");

  useEffect(() => {
    if (userExists || userNotFound) {
      query.product ? product_redirect() : redirect();
    }
  }, [userExists, userNotFound]);

  if (userExists || userNotFound) {
    return (
      <>
        {userNotFound && (
          <Flex direction="column" alignItems="center">
            <Box as="p" color={brand_color} textStyle="h5">
              {translate("activate@user-not-exists-title")}
            </Box>
            <Box textStyle="body2" color="gray.700" my="4">
              {translate("activate@user-not-exists-subtitle")}
            </Box>
            <Spinner color={brand_color} boxSize="12" thickness="0.25rem" />
          </Flex>
        )}
        {userExists && (
          <Flex direction="column" alignItems="center">
            <Box as="p" color={brand_color} textStyle="h5">
              {translate("activate@user-exists-title")}
            </Box>
            <Box textStyle="body2" color="gray.700" my="4">
              {translate("activate@user-exists-subtitle")}
            </Box>
            <Spinner color={brand_color} boxSize="12" thickness="0.25rem" />
          </Flex>
        )}
      </>
    );
  }

  return (
    <Flex direction="row" gridRowGap="8" width="inherit">
      <Flex direction="column">
        <Box as="p" textStyle="h5" color="gray.900">
          {translate("activate@title")}
        </Box>
        <Box textStyle="body2" color="gray.700" my="4">
          {translate("activate@subtitle")}
        </Box>
        <FormControl as="form" display="flex" flexDirection="column" gridRowGap="8" width="100%">
          <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="4">
            <Flex direction="column" width="100%">
              <FormLabel htmlFor="postal_code" textStyle="label.primay" color="black">
                {translate("activate@form-email-label")}
              </FormLabel>
              <Input id="postal_code" data-testid="postal_code" {...register("email")} required />
            </Flex>
          </Flex>
          <Box>
            <Button borderRadius={6} type="submit" minWidth="100%" onClick={handleSubmit(onSubmit)} isLoading={loading}>
              {translate("activate@form-submit-button")}
            </Button>
          </Box>
        </FormControl>
      </Flex>
    </Flex>
  );
}

export default Activate;
