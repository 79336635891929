const yellow = {
  50: "#fff7db",
  100: "#fee8af",
  200: "#fbd881",
  300: "#f8c851",
  400: "#f7bd30",
  500: "#dd9f09",
  600: "#ac7c03",
  700: "#7c5800",
  800: "#4b3500",
  900: "#1d1100",
};

export default yellow;
