import { Box, Button, Flex, Image, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";

import { useNamespace } from "~/hooks";
import { useLanguage } from "~/hooks/useLanguage";
import { ChevronDown, ChevronUp } from "~/theme/icons";
import { LANGUAGES } from "~/utils/locale";

interface SimpleLanguageMenuProps {
  isTransparent?: boolean;
}

const SimpleLanguageMenu: ComponentWithoutChildren<SimpleLanguageMenuProps> = ({ isTransparent = true }) => {
  const { translate } = useNamespace("languageMenu");
  const { changeLanguage, getCurrentLanguage } = useLanguage();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const selectedLanguage = getCurrentLanguage();

  return (
    <Menu onOpen={onOpen} onClose={onClose}>
      <MenuButton
        as={Button}
        height="2.75rem"
        backgroundColor={" transparent"}
        paddingX="6px"
        fontSize="sm"
        color={isTransparent ? "purple.50" : "gray.900"}
        textStyle="h6"
        variant="secondary"
        iconSpacing="4px"
        rightIcon={
          isOpen ? <ChevronUp color={"purple.50"} boxSize="24px" /> : <ChevronDown color={"purple.50"} boxSize="24px" />
        }
      >
        <Flex alignItems="center" gap="6px">
          <Image boxSize="6" src={selectedLanguage?.flag} />
          <span>{selectedLanguage.locale.toUpperCase()}</span>
        </Flex>
      </MenuButton>
      <MenuList backgroundColor="#f0f0f0" borderRadius="1rem" minW="0" width="auto" color="gray.900" fontSize="sm">
        {LANGUAGES.map(({ locale, key, flag }) => {
          const isSelected = locale === selectedLanguage.locale;

          return (
            <MenuItem
              borderRadius="1rem"
              display="flex"
              gap="3"
              value={key}
              key={key}
              onClick={() => changeLanguage(locale)}
            >
              <Box
                position="relative"
                height="5"
                width="5"
                borderRadius="50%"
                border="1px solid"
                borderColor={isSelected ? "purple.400" : "gray.400"}
                _before={
                  isSelected
                    ? {
                        content: '""',
                        display: "inline-block",
                        position: "absolute",
                        top: "3px",
                        left: "3px",
                        height: "3",
                        width: "3",
                        background: "purple.400",
                        borderRadius: "50%",
                      }
                    : {}
                }
              />
              <Flex alignItems="center" gap="1">
                <Image boxSize="6" src={flag} />
                <span>{translate(key as "languageMenu@english")}</span>
              </Flex>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default SimpleLanguageMenu;
