import { AppComponent } from "@fluencyacademy/core-components-web";

const ArrowDiagnonalIcon: AppComponent = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.28292 18.0104C5.8924 17.6199 5.8924 16.9867 6.28292 16.5962L14.2025 8.67659L7.27287 8.67659C6.72614 8.67659 6.28292 8.23337 6.28292 7.68664C6.28292 7.13991 6.72614 6.69669 7.27287 6.69669L16.5966 6.69669C17.1489 6.69669 17.5966 7.14441 17.5966 7.69669V17.0205C17.5966 17.5672 17.1534 18.0104 16.6067 18.0104C16.0599 18.0104 15.6167 17.5672 15.6167 17.0204L15.6167 10.0908L7.69713 18.0104C7.30661 18.4009 6.67344 18.4009 6.28292 18.0104Z" />
  </svg>
);

export { ArrowDiagnonalIcon };
