import { AppComponent, Support } from "@fluencyacademy/core-components-web";

import { useTranslations } from "../hooks/use-translations";
import { PlusIcon } from "../icons/plus-icon";

const MoreDetailsLabel: AppComponent = () => {
  const t = useTranslations("productsHub");

  return (
    <div className="flex flex-row gap-[6px] mt-[10px] items-center">
      <Support variant="label_secondary" className="text-basic-white-50 text-[0.875rem] md:text-[1rem]">
        {t("more_details.label")}
      </Support>
      <PlusIcon />
    </div>
  );
};

export default MoreDetailsLabel;
