import { useLocation } from "react-router-dom";

import { SignIn, StepOne, StepTwo, StepThree, StepFour, Activate } from "./components";

function Outdoor() {
  const { pathname } = useLocation();

  const match = (path: string) => pathname === path;

  if (match("/signin/")) return <SignIn />;

  if (match("/signup/")) return <StepOne />;

  if (match("/signup/validate-email/")) return <StepTwo />;

  if (match("/signup/validate-phone/")) return <StepThree />;

  if (match("/signup/profile/")) return <StepFour />;

  if (pathname.includes("/activate/")) return <Activate />;

  return null;
}

export default Outdoor;
