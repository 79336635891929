import { useEffect } from "react";

import type { Middleware, Key } from "swr";

export const inMemory = new Map<Key, string | null>();

const storeNext: Middleware = (useSWRNext) => {
  const useSetData: ReturnType<Middleware> = (key, ...params) => {
    const { data, ...swr } = useSWRNext(key, ...params);

    useEffect(() => {
      inMemory.set(key, JSON.stringify(data ?? {}));
    }, [data, key]);

    return { ...swr, data };
  };

  return useSetData;
};

export default storeNext;
