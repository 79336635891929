import { createIcon } from "@chakra-ui/icons";

const VisibleOff = createIcon({
  displayName: "VisibleOff",
  viewBox: "0 0 24 24",
  path: (
    <>
      <path
        d="M5.82461 15.69L8.17412 13.357C7.91555 12.6384 7.86776 11.8617 8.0363 11.1172C8.20484 10.3727 8.5828 9.691 9.1262 9.15141C9.6696 8.61183 10.3561 8.23653 11.1059 8.06917C11.8557 7.90181 12.6379 7.94927 13.3616 8.20603L14.9668 6.61203C14.007 6.31017 13.0059 6.15737 11.999 6.15903C10.1153 6.15885 8.27073 6.69333 6.68274 7.69949C5.09476 8.70565 3.82949 10.1416 3.03602 11.838C3.01229 11.889 3 11.9444 3 12.0005C3 12.0566 3.01229 12.1121 3.03602 12.163C3.68113 13.5353 4.63522 14.742 5.82461 15.69Z"
        fill="currentColor"
      />
      <path
        d="M18.1729 8.31006L15.8234 10.6431C16.0824 11.3621 16.1305 12.1394 15.9618 12.8844C15.7932 13.6295 15.4148 14.3116 14.8708 14.8514C14.3268 15.3912 13.6395 15.7664 12.889 15.9333C12.1386 16.1001 11.3558 16.0519 10.6319 15.7941L9.03065 17.3881C11.32 18.1007 13.7922 17.9633 15.9872 17.0014C18.1822 16.0396 19.9506 14.3187 20.9635 12.1591C20.9872 12.1081 20.9995 12.0527 20.9995 11.9966C20.9995 11.9404 20.9872 11.885 20.9635 11.8341C20.3169 10.463 19.3622 9.25739 18.1729 8.31006Z"
        fill="currentColor"
      />
      <path
        d="M12 14.578C12.689 14.5777 13.3497 14.3058 13.8369 13.8221C14.3241 13.3383 14.598 12.6822 14.5982 11.998C14.5982 11.953 14.5982 11.909 14.5982 11.864L11.865 14.575C11.9103 14.578 11.9546 14.578 12 14.578Z"
        fill="currentColor"
      />
      <path
        d="M11.9987 9.42114C11.3097 9.42141 10.649 9.69331 10.1617 10.1771C9.67453 10.6609 9.4007 11.317 9.40044 12.0011C9.40044 12.0461 9.40044 12.0901 9.40044 12.1351L12.1306 9.42414C12.0893 9.42114 12.044 9.42114 11.9987 9.42114Z"
        fill="currentColor"
      />
      <path
        d="M5.23731 17.365C5.10261 17.4989 5.01088 17.6695 4.97369 17.8551C4.93649 18.0407 4.95551 18.2332 5.02834 18.4081C5.10117 18.583 5.22455 18.7325 5.3829 18.8379C5.54125 18.9433 5.72748 18.9997 5.91809 19C6.17416 18.9992 6.41967 18.8987 6.6019 18.72L18.7694 6.63803C18.8593 6.54911 18.9306 6.44345 18.9793 6.3271C19.0279 6.21076 19.053 6.08601 19.053 5.96003C19.053 5.83404 19.0279 5.7093 18.9793 5.59295C18.9306 5.4766 18.8593 5.37094 18.7694 5.28203C18.6801 5.19187 18.5734 5.12045 18.4558 5.07201C18.3382 5.02357 18.2119 4.99909 18.0846 5.00003C17.8284 5.00032 17.5827 5.10093 17.4008 5.28003L14.1096 8.55103L13.023 9.62703L5.23731 17.365Z"
        fill="currentColor"
      />
    </>
  ),
});

export default VisibleOff;
