import { AppComponent } from "@fluencyacademy/core-components-web";

const CoursesBgIcon: AppComponent = ({ className }) => (
  <svg
    className={className}
    width="70"
    height="142"
    viewBox="0 0 70 142"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 853">
      <path
        id="Vector"
        opacity="0.15"
        d="M36.5217 22.0176H70V0H35.4783C17.6522 0 1.65217 13.2819 0.173913 31.4664C-0.956522 44.8374 5.3913 56.7822 15.4783 63.4677C6.17391 69.6183 0 80.3151 0 92.4382V142H21.6522V97.8757C21.6522 84.9504 31.913 74.4319 44.5217 74.4319H70V52.4143H36.5217C28.3478 52.4143 21.6522 45.6397 21.6522 37.1714C21.6522 28.7922 28.3478 22.0176 36.5217 22.0176Z"
        fill="#5C31B6"
      />
    </g>
  </svg>
);

export { CoursesBgIcon };
