import { createIcon } from "@chakra-ui/icons";

const User = createIcon({
  displayName: "User",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12.018 12.018C13.0087 12.018 13.9771 11.7242 14.8009 11.1738C15.6246 10.6234 16.2666 9.84114 16.6457 8.92586C17.0248 8.01059 17.124 7.00345 16.9308 6.03179C16.7375 5.06014 16.2604 4.16763 15.5599 3.4671C14.8594 2.76658 13.9669 2.28952 12.9952 2.09625C12.0236 1.90298 11.0164 2.00217 10.1011 2.38129C9.18586 2.76041 8.40356 3.40242 7.85317 4.22615C7.30277 5.04988 7.009 6.01832 7.009 7.009C7.00874 7.66687 7.13812 8.31834 7.38975 8.92617C7.64139 9.53401 8.01034 10.0863 8.47552 10.5515C8.9407 11.0167 9.49299 11.3856 10.1008 11.6372C10.7087 11.8889 11.3601 12.0183 12.018 12.018ZM12.018 14.518C8.674 14.522 2 16.2 2 19.531V22.031H22.035V19.531C22.035 16.2 15.361 14.522 12.018 14.522V14.518Z"
      fill="currentColor"
    />
  ),
});

export default User;
