import { useRef, useState } from "react";

import { Box, Flex, Button, FormLabel, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
import * as Yup from "yup";

import forgotPasswordReset from "~/api/auth/forgotPasswordReset";
import { useLoggedOut } from "~/auth/Layout";
import useNamespace from "~/hooks/useNamespace";
import { Alert, ImperativeAlertProps, Password } from "~/theme/components";
import { ArrowLeft } from "~/theme/icons";
import { yupTest } from "~/utils/passVerify";

export interface ResetPasswordProps {
  password: string;
  confirm_password: string;
}

const schema = Yup.object({
  password: Yup.string()
    .required("forgotPassword@validation-password-required")
    .test(...yupTest("forgotPassword")),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "forgotPassword@validation-confirm-password-invalid"
  ),
}).required();

function ResetPassword() {
  const { state } = useLocation() as { state: { email: string; code: string } };
  const { goBack, auth } = useLoggedOut();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);
  const { translate } = useNamespace("forgotPassword");
  const alertRef = useRef<ImperativeAlertProps>(null);

  const { formState, register, handleSubmit } = useForm<ResetPasswordProps>({
    resolver: yupResolver(schema),
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
  });

  const onSubmit: SubmitHandler<ResetPasswordProps> = async ({ password }) => {
    try {
      setLoading(true);
      const [, response] = await forgotPasswordReset.post({
        email: state.email,
        password,
        confirmation_code: state.code.toUpperCase(),
      });

      if (response.status === 200) {
        auth.signIn(state.email, password);
      }
    } catch (error) {
      setError(error as Error);
      alertRef.current?.turnOnAlert();
    } finally {
      setLoading(false);
    }
  };

  if (!import.meta.env.JEST_WORKER_ID && !state) return <Navigate to="/" />;

  return (
    <>
      {error && (
        <Alert status="error" ref={alertRef}>
          {error?.message}
        </Alert>
      )}
      <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
        <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={goBack}>
          {translate("forgotPassword@go-back-button")}
        </Button>
        <Box textStyle="h5" color="gray.900">
          {translate("forgotPassword@reset-password-title")}
        </Box>
      </Flex>
      <FormControl display="flex" flexDirection="column" width="100%" gridRowGap={["4", "8"]} as="form">
        <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="password" textStyle="label.primary" color="black">
              {translate("forgotPassword@form-password-label")}
            </FormLabel>
            <Password
              id="password"
              data-testid="password"
              isInvalid={!!formState.errors.password}
              {...register("password")}
              required
              hint
            />
          </Flex>
          <Flex direction="column" width="inherit">
            <FormLabel htmlFor="confirm_password" textStyle="label.primary" color="black">
              {translate("forgotPassword@form-confirm-password-label")}
            </FormLabel>
            <Password
              id="confirm_password"
              data-testid="confirm_password"
              isInvalid={!!formState.errors.confirm_password}
              {...register("confirm_password")}
              required
            />
            {formState.errors.confirm_password && (
              <FormErrorMessage>
                {translate(formState.errors.confirm_password.message as "forgotPassword@validation-password-required")}
              </FormErrorMessage>
            )}
          </Flex>
        </Flex>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          data-testid="submit"
          data-type="primary"
          isDisabled={!formState.isValid}
          isLoading={loading}
          isFullWidth
        >
          {translate("forgotPassword@form-change-password-button")}
        </Button>
      </FormControl>
    </>
  );
}

export default ResetPassword;
