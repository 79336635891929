import { useRef, useState } from "react";

import {
  PinInput,
  PinInputField,
  Button,
  Flex,
  Box,
  FormControl,
  Modal,
  ModalContent,
  ModalOverlay,
  VStack,
  ModalCloseButton,
  useDisclosure,
  Grid,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Navigate, useLocation } from "react-router-dom";
import { useElapsedTime } from "use-elapsed-time";
import * as Yup from "yup";

import { useUpdateUser } from "~/api/users/update";
import { useSendPhoneCode } from "~/api/validation/sendPhone";
import { useValidatePhoneCode } from "~/api/validation/verifyPhone";
import { useLoggedOut } from "~/auth/Layout";
import { useDevice, useNavigate, useTitle } from "~/hooks";
import useNamespace from "~/hooks/useNamespace";
import { Alert, ImperativeAlertProps, Phone, PhoneInputProps } from "~/theme/components";
import { ArrowLeft, CircleQuestion } from "~/theme/icons";
import * as Obfuscate from "~/utils/obfuscate";

interface PhoneProps {
  phone: PhoneInputProps;
}

export interface PhoneNavigateProps {
  isCreatingAccount?: boolean;
}

export enum ConfirmConfig {
  duration = 30,
}

const schema = Yup.object({
  phone: Yup.object({
    number: Yup.string()
      .test("phone-validation", "Número de telefone incorreto", (password) => !/_/.test(password as string))
      .nullable(),
  }),
});

function ValidateCode() {
  const { state } = useLocation() as { state: PhoneNavigateProps };
  const [code, setCode] = useState<string>("");
  const navigate = useNavigate();
  const modal = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const product = useLoggedOut();
  const { translate } = useNamespace("validate");
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying: true,
    duration: ConfirmConfig.duration,
    updateInterval: 1,
    onComplete: () => ({ shouldRepeat: true }),
  });
  const [sendCode, { loading: isSending, error: sendingError }] = useSendPhoneCode();
  const [validateCode, { loading, error: validatingError }] = useValidatePhoneCode();
  const [updateUser] = useUpdateUser();
  const seconds = Math.round(ConfirmConfig.duration - elapsedTime);
  const { isMobileDevice } = useDevice();

  const alertRef = useRef<ImperativeAlertProps>(null);
  const { control, handleSubmit, getValues, formState } = useForm<PhoneProps>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: { phone: { code: "", number: "" } },
  });
  const phoneNumber = `${getValues("phone.code")} ${getValues("phone.number")}`;

  const redirect = () => {
    if (state?.isCreatingAccount)
      navigate(`/signup/profile/${product.id ? `?product=${product.name.toLowerCase()}` : ""}`, {
        state: { canComplete: true },
      });
    else navigate(product.id ? product.callback_url : "/");
  };

  const onSubmit = async () => {
    const response = await validateCode({ code });

    if (response?.data?.active) {
      await updateUser({ phone_number: phoneNumber });
      redirect();
    } else alertRef.current?.turnOnAlert();
  };

  const handleSend = async () => {
    const response = await sendCode({ phone_number: phoneNumber });

    if (response?.data.created) {
      // its not possible to start the isPlaying as false and then set it to true
      // so at the component is mounted, the time is playng and we must reset when the code is sent
      reset();
      onOpen();
    } else alertRef.current?.turnOnAlert();
  };

  const handleResend = async () => {
    const response = await sendCode({ phone_number: phoneNumber });

    if (response?.data.created) reset();
    else alertRef.current?.turnOnAlert();
  };

  useTitle(translate("validate@title", { name: product.name }));

  if (!state) return <Navigate to="/" />;

  return (
    <>
      <Flex direction="column" width="100%" alignItems="flex-start" gridRowGap="2">
        <Button variant="terciary" leftIcon={<ArrowLeft />} onClick={isOpen ? onClose : () => navigate("/")}>
          {translate("validate@back")}
        </Button>
        <Box textStyle="h5" color="gray.900">
          {translate("validate@profile-title-phone")}
        </Box>
        <Alert status="error" ref={alertRef} autoDisplay={false}>
          {validatingError?.message ?? sendingError?.message}
        </Alert>
        <Box textStyle="body2" color="gray.700">
          {isOpen
            ? translate("validate@profile-subtitle-phone", { phone: Obfuscate.phone(phoneNumber) })
            : translate("validate@profile-subtitle-2-phone")}
        </Box>
        {!isOpen && (
          <Button onClick={modal.onOpen} variant="terciary" leftIcon={<CircleQuestion />}>
            <Box fontSize={[".875rem", "1rem"]}>{translate("validate@profile-modal-button-phone")}</Box>
          </Button>
        )}
      </Flex>
      <FormControl
        isInvalid={formState.isDirty}
        display="flex"
        flexDirection="column"
        gridRowGap={["4", "8"]}
        width="100%"
        height="100%"
        as="form"
      >
        {isOpen ? (
          <Flex justifyContent="space-between" width="100%">
            <PinInput
              placeholder=""
              onChange={(value) => setCode(value.toUpperCase())}
              value={code}
              type="alphanumeric"
              otp
            >
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
              <PinInputField data-testid="pin" required />
            </PinInput>
          </Flex>
        ) : (
          <VStack width="inherit" alignItems="flex-start">
            <FormLabel htmlFor="phone_number" textStyle="label.primay" color="black">
              Número de telefone
            </FormLabel>
            <Controller
              control={control}
              name="phone"
              rules={{ required: true }}
              render={({ formState, field }) => (
                <>
                  <Phone isInvalid={!!formState.errors.phone} {...field} />
                  {formState.errors.phone?.number?.message && (
                    <>
                      <FormErrorMessage>{formState.errors.phone.number.message}</FormErrorMessage>
                    </>
                  )}
                </>
              )}
            />
          </VStack>
        )}
        {isOpen ? (
          <Grid gridTemplateColumns="1fr 1fr" width="100%" gap="2" my={["4", "auto"]}>
            <Button
              variant="secondary"
              data-type="secondary"
              onClick={handleResend}
              isDisabled={!(elapsedTime === ConfirmConfig.duration || seconds === 0)}
              isFullWidth
            >
              {translate("validate@form-resend-button")} {seconds ? `(${String(seconds).padStart(2, "0")}s)` : ""}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(onSubmit)}
              isDisabled={code?.length !== 6}
              isLoading={formState.isSubmitting}
              data-testid="submit"
              data-type="primary"
              isFullWidth
            >
              {translate("validate@form-continue-button")}
            </Button>
          </Grid>
        ) : (
          <Grid gridTemplateColumns="7fr 12fr" width="100%" gap="2" my={["4", "auto"]}>
            <Button variant="secondary" data-type="secondary" onClick={redirect} isDisabled={loading} isFullWidth>
              {translate("validate@skip")}
            </Button>
            <Button
              type="submit"
              onClick={handleSend}
              data-type="primary"
              isDisabled={!!formState.errors.phone?.number || !getValues("phone.number")}
              isLoading={isSending}
              data-testid="submit"
              isFullWidth
            >
              {translate("validate@form-continue-button")}
            </Button>
          </Grid>
        )}
      </FormControl>
      <Modal {...modal}>
        <ModalOverlay backgroundColor="rgba(0, 0, 0, .8)" />
        <ModalContent
          width="100%"
          maxWidth="34.5rem"
          alignSelf="center"
          justifySelf="center"
          padding={["6", "12"]}
          margin={["6", "0"]}
          borderRadius="1rem"
        >
          <Box textStyle="h5" fontSize="1.5rem" marginBottom={["8", "12"]}>
            {translate("validate@profile-modal-title-phone")}
          </Box>
          {!isMobileDevice && <ModalCloseButton mr="2" mt="2" />}
          <VStack gap="6" align="start">
            <Box textStyle="body2">{translate("validate@profile-modal-text-1-phone")}</Box>
            <Box textStyle="body2" marginTop="0!important" fontWeight="500">
              {translate("validate@profile-modal-text-2-phone")}
            </Box>
            <Button variant="primary" width="auto" marginTop="0!important" onClick={modal.onClose}>
              {translate("validate@profile-modal-close-button")}
            </Button>
          </VStack>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ValidateCode;
