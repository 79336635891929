import { AppComponent } from "@fluencyacademy/core-components-web";

const ProfileIcon: AppComponent = ({ className }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.7358 0H2.26417C1.01416 0 0 1.0498 0 2.34375V22.6562C0 23.9502 1.01416 25 2.26417 25H22.7358C23.9858 25 25 23.9502 25 22.6562V2.34375C25 1.0498 23.9858 0 22.7358 0ZM10.229 1.5625H14.7573C15.1724 1.5625 15.512 1.91406 15.512 2.34375C15.512 2.77344 15.1724 3.125 14.7573 3.125H10.229C9.81388 3.125 9.47426 2.77344 9.47426 2.34375C9.47426 1.91406 9.81388 1.5625 10.229 1.5625ZM12.4932 7.8125C14.1583 7.8125 15.512 9.21387 15.512 10.9375C15.512 12.6611 14.1583 14.0625 12.4932 14.0625C10.828 14.0625 9.47426 12.6611 9.47426 10.9375C9.47426 9.21387 10.828 7.8125 12.4932 7.8125ZM17.7762 19.375C17.7762 19.8926 17.3045 20.3125 16.7196 20.3125H8.2667C7.68179 20.3125 7.21009 19.8926 7.21009 19.375V18.4375C7.21009 16.8848 8.62991 15.625 10.3799 15.625H10.6158C11.196 15.874 11.8281 16.0156 12.4932 16.0156C13.1583 16.0156 13.7951 15.874 14.3705 15.625H14.6064C16.3564 15.625 17.7762 16.8848 17.7762 18.4375V19.375Z"
      fill="#6B47ED"
    />
  </svg>
);

export { ProfileIcon };
