import { AppComponent } from "@fluencyacademy/core-components-web";

import { useTranslations } from "../hooks/use-translations";
import { HomeProduct } from "../types/product";
import ProductTitle from "./product-title";

const ProductTalksTitle: AppComponent = () => {
  const t = useTranslations("productsHub");

  return (
    <ProductTitle product={HomeProduct.TALKS} title={t("talks_product.title")} bgColor="bg-brand-primary-talks-600" />
  );
};

export default ProductTalksTitle;
