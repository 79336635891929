import { useCallback } from "react";

import { useUpdateEffect } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useSWRConfig } from "swr";

import slice, {
  performCompletePassword,
  performSignIn,
  performSignOut,
  performVerify,
  ValidationTypes,
} from "~/context/features/auth";
import { inMemory } from "~/middleware";

import { useNavigate, useProduct, useStore } from ".";
import useDispatch from "./useDispatch";

interface VerificationPayloadProps {
  validation: ValidationTypes | null;
}

function useAuth() {
  const { cache } = useSWRConfig();
  const auth = useStore(({ auth }) => auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const product = useProduct();
  const productQuery = product.id ? `?product=${product.name.toLowerCase()}` : "";
  const isUserIn = !!auth.session;

  const setAuthenticate = useCallback(
    (email: string, password: string) => {
      dispatch(slice.actions.setAuthenticate({ email, password }));
    },
    [dispatch]
  );

  const handleSignIn = useCallback(
    async (email: string, password: string, redirectTo?: string) => {
      const res = await dispatch(performSignIn({ email, password }));

      if ((res.payload as any).auth_state === "AUTHENTICATED") {
        if (redirectTo) {
          return navigate(redirectTo);
        } else {
          return navigate("/");
        }
      }
    },
    [dispatch, navigate]
  );

  const completeAuthentication = async () => {
    await handleSignIn(auth.authenticate?.email as string, auth.authenticate?.password as string);

    navigate(`/signup/profile/${productQuery}`, {
      state: { canComplete: true },
    });
  };

  const navigateToEmail = useCallback(
    (email: string, password: string) => {
      setAuthenticate(email, password);
      navigate(`/signup/validate-email/${productQuery}`, { state: { email } });
    },
    [navigate, productQuery, setAuthenticate]
  );

  const shouldNavigate = useCallback(
    (verification: ValidationTypes | null) => {
      if (verification === ValidationTypes.EMAIL) return navigateToEmail;

      return null;
    },
    [navigateToEmail]
  );

  const signIn = useCallback(
    async (email: string, password: string, redirectTo?: string) => {
      const { payload } = await dispatch(performVerify({ email }));
      const validation = (payload as VerificationPayloadProps).validation;

      const navigate = shouldNavigate(validation);

      if (navigate) navigate(email, password);
      else handleSignIn(email, password, redirectTo);
    },
    [dispatch, handleSignIn, shouldNavigate]
  );

  const signOut: Replace<typeof slice.actions.signOut, void> = useCallback(() => {
    dispatch(performSignOut());
    for (const key of inMemory.keys()) cache.delete(key);
  }, [cache, dispatch]);

  const completePassword = useCallback(
    async (password: string) => {
      const response = await dispatch(
        performCompletePassword({
          new_password: password,
          email: auth.provisional?.userAttributes.email as string,
          session_token: auth.provisional?.session_token as string,
        })
      );

      if (!("error" in response)) navigate(`/signin/${productQuery}`);
    },
    [auth.provisional?.session_token, auth.provisional?.userAttributes.email, dispatch, navigate, productQuery]
  );

  useUpdateEffect(() => {
    switch (auth.authState) {
      case "NEW_PASSWORD_REQUIRED":
        navigate(`${"/signin/provisional-password/"}${productQuery}`);
    }
  }, [auth.authState, dispatch, navigate, pathname, product.id, product.name, productQuery]);

  return {
    ...auth,
    isUserIn,
    signIn,
    signOut,
    completePassword,
    setAuthenticate,
    completeAuthentication,
  };
}

export default useAuth;
